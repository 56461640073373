import { DELETE, PATCH } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { showNotification } from '@/components/Notification/actions';
import { DocPatchInputSchema } from '@/generated';
import { getParams } from '@core-ui/redux-router';
import { getParsedQuery } from '@core-ui/url';
import { ISagaContext } from 'app/types/common';
import isNil from 'lodash-es/isNil';
import { BACKOFFICE_TABS } from 'pages/backoffice/consts';
import {
  getMasterDocument,
  setMasterDocumentLoading,
} from 'pages/backoffice/Modals/MasterDocumentModal/MasterDocumentModalRoot/actions';
import { IRequestsQueryParams } from 'pages/backoffice/Requests/types';
import { getSingleRequest } from 'pages/backoffice/Requests/ViewRequestPanel/actions';
import { WALLETS_PATHS } from 'pages/backoffice/Wallets/routes';
import { getSingleWalletList } from 'pages/backoffice/Wallets/SingleWalletList/actions';
import { getWallet } from 'pages/backoffice/Wallets/SingleWalletRoot/actions';
import { WalletsPathParams } from 'pages/backoffice/Wallets/types';
import { Action } from 'redux-actions';
import { all, call, getContext, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { IUpdateErpCategory, IUpdateRequest, IUpdateWallet } from './actions';

function* updateErpCategory({ payload }: Action<IUpdateErpCategory>) {
  try {
    yield put(setMasterDocumentLoading({ loading: true }));

    yield call(PATCH, `/document/${payload.documentId}`, {
      xero_category: payload.categoryId,
    } as DocPatchInputSchema);

    yield put(getMasterDocument({ documentId: payload.documentId }));
    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success.text.erp_update',
      })
    );
  } catch (e) {
    yield put(setMasterDocumentLoading({ loading: false }));
    yield put(
      showNotification({
        variant: 'error',
        titleId: 'notification.error.text.erp_update',
      })
    );
  }
}

function* deleteErpCategory({ payload }: Action<actions.IDeleteErpCategory>) {
  try {
    yield put(setMasterDocumentLoading({ loading: true }));

    yield call(DELETE, `/document/${payload.documentId}/xero_category`);

    yield put(getMasterDocument({ documentId: payload.documentId }));
    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success.text.erp_delete',
      })
    );
  } catch (e) {
    yield put(setMasterDocumentLoading({ loading: false }));
    yield call(responseError, e);
  }
}

function* updateWallet({ payload }: Action<IUpdateWallet>) {
  try {
    yield put(setMasterDocumentLoading({ loading: true }));

    yield call(PATCH, `/document/${payload.documentId}`, {
      wallet_id: payload.walletId,
    } as DocPatchInputSchema);

    const history: ISagaContext['history'] = yield getContext('history');
    const isWalletsPage = history.location.pathname.includes(BACKOFFICE_TABS.WALLETS);
    const params = getParams<WalletsPathParams>(history.location.pathname, WALLETS_PATHS);

    if (isWalletsPage && !isNil(params.walletId)) {
      yield put(getWallet(null));
    }

    yield all([
      put(getMasterDocument({ documentId: payload.documentId })),
      put(
        showNotification({
          variant: 'success',
          titleId: 'notification.success.text.wallet_update',
        })
      ),
    ]);
  } catch (e) {
    yield all([put(setMasterDocumentLoading({ loading: false })), call(responseError, e)]);
  }
}

function* deleteWallet({ payload }: Action<actions.IDeleteWallet>) {
  try {
    yield put(setMasterDocumentLoading({ loading: true }));

    yield call(DELETE, `/document/${payload.documentId}/wallet`);

    const history: ISagaContext['history'] = yield getContext('history');
    const isWalletsPage = history.location.pathname.includes(BACKOFFICE_TABS.WALLETS);
    const params = getParams<WalletsPathParams>(history.location.pathname, WALLETS_PATHS);

    if (isWalletsPage && !isNil(params.walletId)) {
      yield all([put(getWallet(null)), put(getSingleWalletList(null))]);
    }

    yield all([
      put(getMasterDocument({ documentId: payload.documentId })),
      put(
        showNotification({
          variant: 'success',
          titleId: 'notification.success.text.wallet_delete',
        })
      ),
    ]);
  } catch (e) {
    yield all([put(setMasterDocumentLoading({ loading: false })), call(responseError, e)]);
  }
}

function* updateRequest({ payload }: Action<IUpdateRequest>) {
  try {
    yield put(setMasterDocumentLoading({ loading: true }));

    yield call(PATCH, `/document/${payload.documentId}`, {
      request_id: payload.requestId,
    } as DocPatchInputSchema);

    const history: ISagaContext['history'] = yield getContext('history');
    const isRequestsPage = history.location.pathname.includes(BACKOFFICE_TABS.REQUESTS);
    const query = getParsedQuery<IRequestsQueryParams>();

    if (isRequestsPage && !isNil(query.selectedRequestId)) {
      yield put(getSingleRequest({ id: query.selectedRequestId }));
    }

    yield all([
      put(getMasterDocument({ documentId: payload.documentId })),
      put(
        showNotification({
          variant: 'success',
          titleId: 'notification.success.text.request_update',
        })
      ),
    ]);
  } catch (e) {
    yield all([put(setMasterDocumentLoading({ loading: false })), call(responseError, e)]);
  }
}

function* deleteRequest({ payload }: Action<actions.IDeleteRequest>) {
  try {
    yield put(setMasterDocumentLoading({ loading: true }));

    yield call(DELETE, `/document/${payload.documentId}/request`);

    const history: ISagaContext['history'] = yield getContext('history');
    const isRequestsPage = history.location.pathname.includes(BACKOFFICE_TABS.REQUESTS);
    const query = getParsedQuery<IRequestsQueryParams>();

    if (isRequestsPage && !isNil(query.selectedRequestId)) {
      yield put(getSingleRequest({ id: query.selectedRequestId }));
    }

    yield all([
      put(getMasterDocument({ documentId: payload.documentId })),
      put(
        showNotification({
          variant: 'success',
          titleId: 'notification.success.text.request_delete',
        })
      ),
    ]);
  } catch (e) {
    yield all([put(setMasterDocumentLoading({ loading: false })), call(responseError, e)]);
  }
}

function* updateVisibilityInAnalytics({ payload }: Action<actions.IUpdateVisibilityInAnalytics>) {
  try {
    yield put(setMasterDocumentLoading({ loading: true }));

    yield call(PATCH, `/document/${payload.documentId}`, {
      hide_from_analytics: payload.hide,
    } as DocPatchInputSchema);

    yield put(getMasterDocument({ documentId: payload.documentId }));
    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success.text.visibility_in_analytics_update',
      })
    );
  } catch (e) {
    yield put(setMasterDocumentLoading({ loading: false }));
    yield call(responseError, e);
  }
}

export default [
  takeLatest(actions.updateErpCategory, updateErpCategory),
  takeLatest(actions.deleteErpCategory, deleteErpCategory),
  takeLatest(actions.updateWallet, updateWallet),
  takeLatest(actions.deleteWallet, deleteWallet),
  takeLatest(actions.updateRequest, updateRequest),
  takeLatest(actions.deleteRequest, deleteRequest),
  takeLatest(actions.updateVisibilityInAnalytics, updateVisibilityInAnalytics),
];
