import { ISendToXeroItemModal } from '@/pages/backoffice/Modals/types';

export default class SendToXeroItemModalData implements ISendToXeroItemModal {
  invoiceCode?: string;
  documentId?: number;
  walletId?: number;
  requestId?: number;

  constructor(invoiceCode?: string, documentId?: number, walletId?: number, requestId?: number) {
    this.invoiceCode = invoiceCode;
    this.documentId = documentId;
    this.walletId = walletId;
    this.requestId = requestId;
  }
}
