import { COLOURS, Z_INDEXES } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { MASTER_DOCUMENT_HEADER_HEIGHT } from 'pages/backoffice/Modals/MasterDocumentModal/consts';

export default (): Record<string, SxProps<Theme>> => ({
  container: {
    height: MASTER_DOCUMENT_HEADER_HEIGHT,
    justifyContent: 'space-between',
    padding: 5,
    backgroundColor: COLOURS.WHITE,
    position: 'sticky',
    top: 0,
    flexWrap: 'nowrap',
    zIndex: Z_INDEXES.TABLE_HEADER,
    borderBottom: `1px solid ${COLOURS.Coconut.Solid[300]}`,
  },

  titleContainer: {
    flexWrap: 'nowrap',
    overflow: 'hidden',
  },

  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
