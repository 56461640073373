import { PeriodicityInputEnum } from '@/generated';
import capitalize from 'lodash-es/capitalize';

export const paymentPeriodicityOptions = Object.values(PeriodicityInputEnum).map((type) => ({
  label: capitalize(type.replace(/_/g, ' ')),
  value: type,
}));

export const NEGATIVE_FLOAT_NUMBER_REGEX = /^-\d*\.?\d+$/;

export const FLOAT_NUMBER_REGEX = /[+-]?(\d*[.])\d+/;
