import { vehicleIdSelector } from 'app/selectors';
import { getClientCostArticlesDictOptions, getRequestsDictOptions } from 'dictionary/selector';
import {
  getMasterDocumentRootLoading,
  getMasterDocumentRootValue,
} from 'pages/backoffice/Modals/MasterDocumentModal/MasterDocumentModalRoot/selector';
import { getWalletsOptions } from 'pages/backoffice/Wallets/WalletsList/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [
    getMasterDocumentRootValue,
    vehicleIdSelector,
    getClientCostArticlesDictOptions,
    getWalletsOptions,
    getRequestsDictOptions,
    getMasterDocumentRootLoading,
  ],
  (document, boatId, clientCostArticlesOptions, walletOptions, requestOptions, loading) => ({
    document,
    boatId,
    clientCostArticlesOptions,
    walletOptions,
    requestOptions,
    loading,
  })
);
