import { setRequiredFields } from '@/app/utils/app';
import { ValidationErrors } from 'final-form';
import { IRequestForm } from '../../types';

export default (values: IRequestForm): ValidationErrors => {
  const errors: ValidationErrors = {};

  setRequiredFields(values, errors, ['name', 'description', 'department_id', 'cost_centers_ids']);

  return errors;
};
