import { getList, setList, setLoading } from '@core-ui/reducers_handlers';
import { DocumentLinesResponse } from '@/generated';
import { createAction } from 'redux-actions';

export const reducerName = 'ledger.document.lines';

export const setLedgerLinesListLoading = setLoading(reducerName);

export const getLedgerDocumentLines = getList(reducerName);
export const setLedgerDocumentLines = setList<DocumentLinesResponse>(reducerName);

export interface IUpdateLineErpCategory {
  documentId: number;
  lineId: number;
  categoryId: string;
}

export const updateLineErpCategory = createAction<IUpdateLineErpCategory>(`${reducerName}.UPDATE_LINE_ERP_CATEGORY`);
