import { useTableDynamicShadows } from '@core-ui/table';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import React, { useRef } from 'react';
import ChunkLoader from '@/components/ChunkLoader';
import { useAppSelector } from '@/hooks/useAppSelector';
import { getChunkLedgerList } from '@/pages/backoffice/Ledger/LedgerList/actions';
import LedgerListRow from '@/pages/backoffice/Ledger/LedgerList/LedgerListRow';
import { chunkLoaderSelector } from '@/pages/backoffice/Ledger/LedgerList/selector';
import TableHeader from '@/pages/backoffice/Ledger/LedgerList/TableHeader';
import selector from './selector';
import useStyles from './styles';

const LedgerListTable = () => {
  const {
    items,
    amountFormatter,
    getCurrencyCode,
    budgetApproveFlowActive,
    levelUserApprovalEnabled,
    requestsOptions,
    boatId,
  } = useAppSelector(selector);

  const styles = useStyles();
  const ref = useRef<Nullable<HTMLDivElement>>(null);
  const applyRef = useTableDynamicShadows(ref);

  return (
    <Grid item sx={styles.tableContainer} ref={applyRef}>
      <Table stickyHeader>
        <TableHeader />

        <TableBody>
          {items?.map((item) => (
            <LedgerListRow
              key={item.id}
              item={item}
              amountFormatter={amountFormatter}
              getCurrencyCode={getCurrencyCode}
              budgetApproveFlowActive={budgetApproveFlowActive}
              levelUserApprovalEnabled={levelUserApprovalEnabled}
              requestsOptions={requestsOptions}
              boatId={boatId}
            />
          ))}
        </TableBody>
      </Table>

      <ChunkLoader selector={chunkLoaderSelector} getChunk={getChunkLedgerList} />
    </Grid>
  );
};

export default LedgerListTable;
