import { getBudgetApproveFlowEnabled } from 'app/selectors';
import {
  getMasterDocumentRootLoading,
  getMasterDocumentRootValue,
} from 'pages/backoffice/Modals/MasterDocumentModal/MasterDocumentModalRoot/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [getMasterDocumentRootValue, getMasterDocumentRootLoading, getBudgetApproveFlowEnabled],
  (document, loading, budgetApproveFlowActive) => ({
    document: document ?? null,
    vendorName: document?.vendor,
    loading,
    budgetApproveFlowActive,
  })
);
