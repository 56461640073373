import { IApproveDocument } from '@/pages/backoffice/Modals/ApproveDocumentModal/actions';

export default class ApproveDocumentModalData implements IApproveDocument {
  id: number;
  reloadDocumentList: boolean;

  constructor(id: number, reloadDocumentList: boolean) {
    this.id = id;
    this.reloadDocumentList = reloadDocumentList;
  }
}
