import { SxProps } from '@mui/material/styles';
import { MASTER_DOCUMENT_INFO_TAB_LABEL_WIDTH } from 'pages/backoffice/Modals/MasterDocumentModal/consts';

export default (props: { loading: boolean }): Record<string, SxProps> => ({
  container: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    flexWrap: 'nowrap',
  },

  rowText: {
    minWidth: MASTER_DOCUMENT_INFO_TAB_LABEL_WIDTH,
    width: MASTER_DOCUMENT_INFO_TAB_LABEL_WIDTH,
  },

  visibilityInAnalyticsContainer: {
    cursor: props.loading ? 'default' : 'pointer',
  },
});
