import { SxProps } from '@mui/material/styles';
import { SINGLE_DOCUMENT_INFO_TAB_LABEL_WIDTH } from '@/pages/backoffice/Ledger/consts';

export default (props: { loading: boolean }): Record<string, SxProps> => ({
  container: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    flexWrap: 'nowrap',
  },
  rowText: {
    minWidth: SINGLE_DOCUMENT_INFO_TAB_LABEL_WIDTH,
    width: SINGLE_DOCUMENT_INFO_TAB_LABEL_WIDTH,
  },
  visibilityInAnalyticsContainer: {
    cursor: props.loading ? 'default' : 'pointer',
  },
});
