import Autocomplete from '@core-ui/autocomplete';
import Button from '@core-ui/button';
import { useFilter } from '@core-ui/hooks';
import Switch from '@core-ui/switch';
import TextField from '@core-ui/text_field';
import { Nullable } from '@core-ui/types';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { DATE_FORMAT, DOCUMENT_TYPE_OPTIONS, RANGE_PICKER_LABELS, UPLOADED_FROM_OPTIONS } from '@/app/consts/app';
import { dateToDayEndUTC, dateToDayStartUTC, dateToUTC } from '@/app/utils/dates';
import DatePicker, { RangePickerProps } from '@core-ui/datepicker';
import { useAppSelector } from '@/hooks/useAppSelector';
import {
  resetLedgerFilters,
  setTextFilter,
  updateLedgerFiltersQuery,
} from '@/pages/backoffice/Ledger/LedgerFilters/actions';
import { ILedgerFiltersState } from '@/pages/backoffice/Ledger/LedgerFilters/reducer';
import { getLedgerList, setLedgerListLoading } from '@/pages/backoffice/Ledger/LedgerList/actions';
import { Calculator, UserList, X } from '@phosphor-icons/react';
import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'components/Intl';
import { batch, useDispatch } from 'react-redux';
import selector from './selector';
import useStyles from './styles';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const FiltersDrawer: FC<IProps> = (props) => {
  const { open, onClose } = props;

  const styles = useStyles();
  const dispatch = useDispatch();
  const {
    filters,
    uploadedByOptions,
    clientCostArticlesOptions,
    costCentersOptions,
    walletsOptions,
    vendorsOptions,
    costArticlesOptions,
    requestsOptions,
    levelUserApprovalEnabled,
  } = useAppSelector(selector);

  const setLoadingCallback = useCallback(
    () => dispatch(setLedgerListLoading({ loading: true })),
    [setLedgerListLoading]
  );

  const handleFilterChange = useFilter<ILedgerFiltersState>({
    getList: getLedgerList,
    setFilter: setTextFilter,
    onChange: setLoadingCallback,
  });

  const handleClearFilters = () => {
    batch(() => {
      dispatch(resetLedgerFilters());
      dispatch(updateLedgerFiltersQuery());
      dispatch(getLedgerList(null));
    });
  };

  const handleToggleErpUploadPending = () => {
    handleFilterChange('erpUploadPending')(!filters.erpUploadPending);
  };

  const handleToggleMyDuty = () => {
    handleFilterChange('myDuty')(!filters.myDuty);
  };

  const handleDateChange: RangePickerProps['onChange'] = (dates) => {
    batch(() => {
      handleFilterChange('dateFrom')(dates?.[0] ? dateToDayStartUTC(dates[0]) : null);
      handleFilterChange('dateTo')(dates?.[1] ? dateToDayEndUTC(dates[1]) : null);
    });
  };

  const pickerValue: [Nullable<Date>, Nullable<Date>] = [null, null];

  if (filters.dateFrom) {
    pickerValue[0] = dateToUTC(+filters.dateFrom);
  }

  if (filters.dateTo) {
    pickerValue[1] = dateToUTC(+filters.dateTo);
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: styles.drawer,
      }}
    >
      <Grid container direction="column" gap={2}>
        <Grid item container paddingX={4} paddingY={6} component={Paper}>
          <Button variant="outlined" onClick={onClose} sx={styles.button} icon={<X />} />

          <Typography variant="h5" alignSelf="center" marginLeft={19.5}>
            <FormattedMessage id="label.global_filters" />
          </Typography>
        </Grid>

        <Grid item container direction="column" gap={4} paddingX={4} paddingY={6} component={Paper}>
          <Grid item>
            <Typography variant="h4" alignSelf="center">
              <FormattedMessage id="label.document_info" />
            </Typography>
          </Grid>

          <Grid item sx={styles.gridItem}>
            <Autocomplete
              fullWidth
              multiple
              limitTags={2}
              label={<FormattedMessage id="label.document_type" />}
              value={filters.documentType}
              options={DOCUMENT_TYPE_OPTIONS}
              onChange={handleFilterChange('documentType')}
            />
          </Grid>

          <Grid item sx={styles.gridItem}>
            <Autocomplete
              fullWidth
              multiple
              limitTags={2}
              label={<FormattedMessage id="label.vendor_name" />}
              value={filters.vendor}
              options={vendorsOptions}
              onChange={handleFilterChange('vendor')}
            />
          </Grid>

          <Grid item sx={styles.gridItem}>
            <TextField
              fullWidth
              label={<FormattedMessage id="label.document_name" />}
              value={filters.name}
              // TODO: тип параметра onChange в компоненте TextField не соответствует handleFilterChange
              // @ts-ignore
              onChange={handleFilterChange('name')}
            />
          </Grid>

          {/* todo: бэк не успевает запилить локации, отложено */}
          {/* <Grid item sx={styles.gridItem}> */}
          {/*   <Autocomplete */}
          {/*     fullWidth */}
          {/*     multiple */}
          {/*     limitTags={2} */}
          {/*     label={<FormattedMessage id="label.location" />} */}
          {/*     // value={} */}
          {/*     // options={} */}
          {/*     onChange={handleFilterChange('location')} */}
          {/*   /> */}
          {/* </Grid> */}

          <Grid item sx={styles.gridItem}>
            <Autocomplete
              fullWidth
              multiple
              limitTags={1}
              label={<FormattedMessage id="label.uploaded_by" />}
              value={filters.uploadedBy}
              options={uploadedByOptions}
              onChange={handleFilterChange('uploadedBy')}
            />
          </Grid>

          <Grid item sx={styles.gridItem}>
            <Autocomplete
              fullWidth
              multiple
              limitTags={1}
              label={<FormattedMessage id="label.uploaded_from" />}
              value={filters.uploadedFrom}
              options={UPLOADED_FROM_OPTIONS}
              onChange={handleFilterChange('uploadedFrom')}
            />
          </Grid>

          <Grid item container direction="column" gap={1}>
            <Typography fontSize="small" fontWeight="semiBold">
              <FormattedMessage id="label.document_date_period" />
            </Typography>

            <DatePicker<RangePickerProps>
              dateTimeFormat={DATE_FORMAT}
              value={pickerValue}
              onChange={handleDateChange}
              label={RANGE_PICKER_LABELS}
              size="medium"
              view="rangepicker"
              openOnFocus
              data-testid="ledger-drawer-rangepicker"
            />
          </Grid>

          <Grid item container direction="column" gap={1}>
            <Typography fontSize="small" fontWeight="semiBold">
              <FormattedMessage id="label.amount" />
            </Typography>

            <Grid item container gap={1} wrap="nowrap">
              <TextField
                type="number"
                value={filters.totalFrom}
                label={<FormattedMessage id="label.from" />}
                // TODO: тип параметра onChange в компоненте TextField не соответствует handleFilterChange
                // @ts-ignore
                onChange={handleFilterChange('totalFrom')}
              />

              <TextField
                type="number"
                value={filters.totalTo}
                label={<FormattedMessage id="label.to" />}
                // TODO: тип параметра onChange в компоненте TextField не соответствует handleFilterChange
                // @ts-ignore
                onChange={handleFilterChange('totalTo')}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item container direction="column" gap={4} paddingX={4} paddingY={6} component={Paper}>
          <Grid item>
            <Typography variant="h4" alignSelf="center">
              <FormattedMessage id="label.details" />
            </Typography>
          </Grid>

          <Grid item sx={styles.gridItem}>
            <Autocomplete
              fullWidth
              multiple
              limitTags={3}
              label={<FormattedMessage id="cost.center" />}
              value={filters.costCenters}
              options={costCentersOptions}
              onChange={handleFilterChange('costCenters')}
            />
          </Grid>

          <Grid item sx={styles.gridItem}>
            <Autocomplete
              fullWidth
              multiple
              limitTags={2}
              label={<FormattedMessage id="cost.article" />}
              value={filters.costArticles}
              options={costArticlesOptions}
              onChange={handleFilterChange('costArticles')}
            />
          </Grid>

          <Grid item sx={styles.gridItem}>
            <Autocomplete
              fullWidth
              multiple
              limitTags={2}
              label={<FormattedMessage id="label.erp_category" />}
              value={filters.erpCategories}
              options={clientCostArticlesOptions}
              onChange={handleFilterChange('erpCategories')}
            />
          </Grid>

          {/* todo: теги будут добавлены позже и во всех разделах сразу */}
          {/* <Grid item sx={styles.gridItem}> */}
          {/*   <Autocomplete */}
          {/*     fullWidth */}
          {/*     multiple */}
          {/*     limitTags={2} */}
          {/*     label={<FormattedMessage id="label.tags" />} */}
          {/*     value={} */}
          {/*     options={} */}
          {/*     onChange={handleFilterChange('tags')} */}
          {/*   /> */}
          {/* </Grid> */}

          <Grid item sx={styles.gridItem}>
            <Autocomplete
              fullWidth
              multiple
              limitTags={2}
              label={<FormattedMessage id="label.wallet" />}
              value={filters.walletIds}
              options={walletsOptions}
              onChange={handleFilterChange('walletIds')}
            />
          </Grid>

          {/* todo: контракты будут добавлены после создания страницы контрактов */}
          {/* <Grid item sx={styles.gridItem}> */}
          {/*   <Autocomplete */}
          {/*     fullWidth */}
          {/*     multiple */}
          {/*     limitTags={2} */}
          {/*     label={<FormattedMessage id="label.contract" />} */}
          {/*     // value={} */}
          {/*     // options={} */}
          {/*     onChange={handleFilterChange('contractIds')} */}
          {/*   /> */}
          {/* </Grid> */}

          <Grid item sx={styles.gridItem}>
            <Autocomplete
              fullWidth
              multiple
              limitTags={2}
              label={<FormattedMessage id="label.request" />}
              value={filters.requestIds}
              options={requestsOptions}
              onChange={handleFilterChange('requestIds')}
            />
          </Grid>

          <Grid item container gap={8}>
            {levelUserApprovalEnabled && (
              <Grid
                item
                container
                width="auto"
                direction="column"
                wrap="nowrap"
                gap={2}
                onClick={handleToggleMyDuty}
                sx={styles.switchContainer}
              >
                <Typography fontSize="small">
                  <FormattedMessage id="label.my_duty" />
                </Typography>

                <Grid item container gap={2}>
                  <SvgIcon>
                    <UserList />
                  </SvgIcon>
                  <Switch checked={filters.myDuty} />
                </Grid>
              </Grid>
            )}

            <Grid
              item
              container
              width="auto"
              direction="column"
              gap={2}
              onClick={handleToggleErpUploadPending}
              sx={styles.switchContainer}
            >
              <Typography fontSize="small">
                <FormattedMessage id="label.erp_upload_pending" />
              </Typography>

              <Grid item container gap={2}>
                <SvgIcon>
                  <Calculator />
                </SvgIcon>
                <Switch checked={filters.erpUploadPending} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container direction="column" gap={4} paddingX={4} paddingY={6} component={Paper}>
          <Button
            variant="outlined"
            size="large"
            sx={styles.button}
            label={<FormattedMessage id="filters.reset" />}
            onClick={handleClearFilters}
          />
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default FiltersDrawer;
