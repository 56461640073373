import { buildURL } from '@core-ui/url';
import { Action } from 'redux-actions';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { PATCH, POST } from '@/api/oceanApi';
import { ROUTES } from '@/app/consts/routes';
import { responseError } from '@/app/sagas';
import { vehicleIdSelector } from '@/app/selectors';
import { dateToDayStartUTC } from '@/app/utils/dates';
import { showNotification } from '@/components/Notification/actions';
import {
  ContractInputSchema,
  ContractNotificationSchema,
  ContractOutputSchema,
  ContractUpdateSchema,
} from '@/generated';
import history from '@/history';
import { BACKOFFICE_TABS } from '@/pages/backoffice/consts';
import { SINGLE_CONTRACT_TAB_ENUM } from '@/pages/backoffice/Contracts/consts';
import { getContractsList } from '@/pages/backoffice/Contracts/ContractsList/actions';
import { getSingleContract } from '@/pages/backoffice/Contracts/SingleContractRoot/actions';
import { closeBackofficeModal, toggleModalLoading } from '@/pages/backoffice/Modals/actions';
import * as actions from './actions';
import { ISubmitCreateContractForm, ISubmitEditContractForm } from './actions';

const createFormData = (files: File[], contractId: number) => {
  const formData = new FormData();

  // is_contract_source флаг необходим чтобы бэк привязал данные файлы к контракту в качестве soure файлов
  formData.append('is_contract_source', 'true');
  formData.append('contract_id', String(contractId));
  files.forEach((file) => {
    formData.append('fileobjects', file);
  });

  return formData;
};

function* submitCreateContractForm({ payload }: Action<ISubmitCreateContractForm>) {
  try {
    yield put(toggleModalLoading());

    const { contractData, shouldRedirect } = payload;
    const boatId: number = yield select(vehicleIdSelector);

    let notification: ContractNotificationSchema | undefined;

    if (
      contractData.notification_active &&
      contractData.notification?.notify_base_date &&
      contractData.notification?.notify_every_days
    ) {
      notification = {
        notify_every_days: Number(contractData.notification.notify_every_days),
        notify_base_date: dateToDayStartUTC(contractData.notification.notify_base_date),
      };
    }

    const newContract: ContractOutputSchema = yield call(POST, '/contract', {
      boat_id: boatId,
      name: contractData.name,
      vendor_id: contractData.vendor_id,
      expires_at: contractData.expires_at && dateToDayStartUTC(contractData.expires_at),
      currency_id: contractData.currency_id,
      periodicity: contractData.periodicity,
      payment_date: contractData.payment_date && dateToDayStartUTC(contractData.payment_date),
      amount: Number(contractData.amount),
      description: contractData.description,
      manager_info: contractData.manager_info,
      notification,
    } as ContractInputSchema);

    if (contractData?.files.length > 0) {
      const formData = createFormData(contractData.files, newContract.id);

      yield call(POST, '/files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }

    yield all([
      put(closeBackofficeModal()),
      put(
        showNotification({
          variant: 'success',
          titleId: 'contracts.create.success',
        })
      ),
    ]);

    if (shouldRedirect) {
      history.push(
        buildURL(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.CONTRACTS}/${newContract.id}`, {
          tab: SINGLE_CONTRACT_TAB_ENUM.INFORMATION,
        })
      );
    } else {
      yield put(getContractsList(null));
    }
  } catch (e) {
    yield all([put(toggleModalLoading()), call(responseError, e)]);
  }
}

function* submitEditContractForm({ payload }: Action<ISubmitEditContractForm>) {
  try {
    yield put(toggleModalLoading());

    const { contractData, shouldRedirect, refetchSinglePage } = payload;
    const boatId: number = yield select(vehicleIdSelector);

    let notification: ContractNotificationSchema | undefined;

    if (
      contractData.notification_active &&
      contractData.notification?.notify_base_date &&
      contractData.notification?.notify_every_days
    ) {
      notification = {
        notify_every_days: Number(contractData.notification.notify_every_days),
        notify_base_date: dateToDayStartUTC(contractData.notification.notify_base_date),
      };
    }

    const newContract: ContractOutputSchema = yield call(PATCH, `/contracts/${contractData.id}`, {
      boat_id: boatId,
      name: contractData.name,
      vendor_id: contractData.vendor_id,
      expires_at: contractData.expires_at && dateToDayStartUTC(contractData.expires_at),
      currency_id: contractData.currency_id,
      periodicity: contractData.periodicity,
      payment_date: contractData.payment_date && dateToDayStartUTC(contractData.payment_date),
      amount: Number(contractData.amount),
      description: contractData.description,
      manager_info: contractData.manager_info,
      notification: contractData.notification_active ? notification : null,
    } as ContractUpdateSchema);

    if (contractData?.files.length > 0) {
      const formData = createFormData(contractData.files, newContract.id);

      yield call(POST, '/files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }

    yield put(closeBackofficeModal());

    if (shouldRedirect) {
      history.push(
        buildURL(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.CONTRACTS}/${newContract.id}`, {
          tab: SINGLE_CONTRACT_TAB_ENUM.INFORMATION,
        })
      );
    } else if (refetchSinglePage) {
      yield put(getSingleContract(null));
    } else {
      yield put(getContractsList(null));
    }

    yield put(
      showNotification({
        variant: 'success',
        titleId: 'contracts.update.success',
      })
    );
  } catch (e) {
    yield all([put(toggleModalLoading()), call(responseError, e)]);
  }
}

export default [
  takeLatest(actions.submitCreateContractForm, submitCreateContractForm),
  takeLatest(actions.submitEditContractForm, submitEditContractForm),
];
