import { createAction } from 'redux-actions';

export const reducerName = 'modals.master_document.information';

export interface IUpdateErpCategory {
  documentId: number;
  categoryId: string;
}

export const updateErpCategory = createAction<IUpdateErpCategory>(`${reducerName}.UPDATE_ERP_CATEGORY`);

export interface IUpdateWallet {
  documentId: number;
  walletId: number;
}

export const updateWallet = createAction<IUpdateWallet>(`${reducerName}.UPDATE_WALLET`);

export interface IUpdateRequest {
  documentId: number;
  requestId: number;
}

export const updateRequest = createAction<IUpdateRequest>(`${reducerName}.UPDATE_REQUEST`);

export interface IDeleteErpCategory {
  documentId: number;
}

export const deleteErpCategory = createAction<IDeleteErpCategory>(`${reducerName}.DELETE_ERP_CATEGORY`);

export interface IDeleteWallet {
  documentId: number;
}

export const deleteWallet = createAction<IDeleteWallet>(`${reducerName}.DELETE_WALLET`);

export interface IDeleteRequest {
  documentId: number;
}

export const deleteRequest = createAction<IDeleteRequest>(`${reducerName}.DELETE_REQUEST`);

export interface IUpdateVisibilityInAnalytics {
  documentId: number;
  hide: boolean;
}

export const updateVisibilityInAnalytics = createAction<IUpdateVisibilityInAnalytics>(
  `${reducerName}.UPDATE_VISIBILITY_IN_ANALYTICS`
);
