import { ScrollMemory } from '@core-ui/redux-router';
import NoDataContainer from '@/components/NoDataContainer';
import NoDataPlaceholder from '@/components/NoDataPlaceholder';
import NoFiltratedDataPlaceholder from '@/components/NoFiltratedDataPlaceholder';
import TableSkeleton from '@/components/TableSkeleton';
import { useAppSelector } from '@/hooks/useAppSelector';
import { resetLedgerFilters, updateLedgerFiltersQuery } from '@/pages/backoffice/Ledger/LedgerFilters/actions';
import { getLedgerList } from '@/pages/backoffice/Ledger/LedgerList/actions';
import React from 'react';
import { batch, useDispatch } from 'react-redux';
import LedgerListTable from '@/pages/backoffice/Ledger/LedgerList/LedgerListTable/LedgerListTable';
import { getFiltersApplied, getNoDataContainerData } from './selector';
import useStyles from './styles';

const SCROLLABLE_CONTAINER_ID = 'ledger-list';

const LedgerList = () => {
  const isFiltersApplied = useAppSelector(getFiltersApplied);

  const dispatch = useDispatch();
  const styles = useStyles();

  const handleClearFilters = () => {
    batch(() => {
      dispatch(resetLedgerFilters());
      dispatch(updateLedgerFiltersQuery());
      dispatch(getLedgerList(null));
    });
  };

  const noDataContent = isFiltersApplied ? (
    <NoFiltratedDataPlaceholder clearFilters={handleClearFilters} />
  ) : (
    <NoDataPlaceholder subtitleId="ledger.table.no_data.subtitle" />
  );

  return (
    <>
      <ScrollMemory elementID={SCROLLABLE_CONTAINER_ID} />

      <NoDataContainer
        sx={styles.noDataContainer}
        selector={getNoDataContainerData}
        loader={<TableSkeleton rows={10} />}
        noDataContent={noDataContent}
        id={SCROLLABLE_CONTAINER_ID}
      >
        <LedgerListTable />
      </NoDataContainer>
    </>
  );
};

export default LedgerList;
