import { getParams } from '@core-ui/redux-router';
import { buildURL } from '@core-ui/url';
import { PATCH, POST } from '@/api/oceanApi';
import { ROUTES } from '@/app/consts/routes';
import { vehicleIdSelector } from '@/app/selectors';
import { ISagaContext } from '@/app/types/common';
import { showNotification } from '@/components/Notification/actions';
import { WalletOutputSchema } from '@/generated';
import { BACKOFFICE_TABS } from '@/pages/backoffice/consts';
import { closeBackofficeModal, toggleModalLoading } from '@/pages/backoffice/Modals/actions';
import { getInitialWalletData } from '@/pages/backoffice/Modals/CreateEditWalletModal/selector';
import { WALLETS_PATHS } from '@/pages/backoffice/Wallets/routes';
import { getSingleWalletList } from '@/pages/backoffice/Wallets/SingleWalletList/actions';
import { getWallet } from '@/pages/backoffice/Wallets/SingleWalletRoot/actions';
import { WalletsPathParams } from '@/pages/backoffice/Wallets/types';
import { getWalletsList } from '@/pages/backoffice/Wallets/WalletsList/actions';
import { put, select } from '@redux-saga/core/effects';
import { Action } from 'redux-actions';
import { all, call, getContext, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* submitCreateWalletForm({ payload }: Action<actions.ISubmitCreateWalletForm>) {
  const history: ISagaContext['history'] = yield getContext('history');

  try {
    yield put(toggleModalLoading());

    const boat_id: number = yield select(vehicleIdSelector);

    const response: WalletOutputSchema = yield call(POST, '/wallet', {
      ...payload.walletData,
      boat_id,
    });

    yield put(
      showNotification({
        variant: 'success',
        titleId: 'wallets.create.success',
      })
    );
    yield put(closeBackofficeModal());

    if (payload.shouldRedirect) {
      history.push(buildURL(`/${boat_id}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.WALLETS}/${response.id}`));
    } else {
      yield put(getSingleWalletList(null));
    }
  } catch (e) {
    yield all([
      put(toggleModalLoading()),
      put(
        showNotification({
          variant: 'error',
          titleId: 'wallets.create.error',
        })
      ),
    ]);
  }
}

function* submitEditWalletForm({ payload }: Action<actions.ISubmitEditWalletForm>) {
  const history: ISagaContext['history'] = yield getContext('history');

  try {
    yield put(toggleModalLoading());

    const wallet: WalletOutputSchema = yield select(getInitialWalletData);

    yield call(PATCH, `/wallets/${wallet.id}`, {
      ...payload.walletData,
    });

    yield all([
      put(closeBackofficeModal()),
      put(
        showNotification({
          variant: 'success',
          titleId: 'wallets.update.success',
        })
      ),
    ]);

    if (payload.shouldRedirect) {
      history.push(buildURL(`/${wallet.boat_id}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.WALLETS}/${wallet.id}`));
    } else {
      const params = getParams<WalletsPathParams>(history.location.pathname, WALLETS_PATHS);

      // we will get id from the path only if we are on a singleWallet page
      if (params.walletId) {
        yield put(getWallet(null));
      } else {
        yield put(getWalletsList(null));
      }
    }
  } catch (e) {
    yield all([
      put(toggleModalLoading()),
      put(
        showNotification({
          variant: 'error',
          titleId: 'wallets.update.error',
        })
      ),
    ]);
  }
}

export default [
  takeLatest(actions.submitCreateWalletForm, submitCreateWalletForm),
  takeLatest(actions.submitEditWalletForm, submitEditWalletForm),
];
