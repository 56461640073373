import { TableCell, TableRow, useTableDynamicShadows } from '@core-ui/table';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import { generateEmptyArray } from 'app/utils/app';
import { FormattedMessage } from 'components/Intl';
import NoDataContainer from 'components/NoDataContainer';
import TableSkeleton from 'components/TableSkeleton';
import { useAppSelector } from 'hooks/useAppSelector';
import {
  MasterDocumentModalLinesRow,
  MasterDocumentModalLinesTableHeader,
} from 'pages/backoffice/Modals/MasterDocumentModal/MasterDocumentModalLines/components';
import { getNoDataContainerData } from 'pages/backoffice/Modals/MasterDocumentModal/MasterDocumentModalLines/selector';
import React, { useRef } from 'react';
import selector from './selector';
import useStyles from './styles';

const MasterDocumentModalLines = () => {
  const { items, total, amountFormatter, getCurrencyCode, clientCostArticlesOptions, documentId } =
    useAppSelector(selector);

  const styles = useStyles();
  const ref = useRef<Nullable<HTMLDivElement>>(null);
  const applyRef = useTableDynamicShadows(ref);

  return (
    <NoDataContainer selector={getNoDataContainerData} loader={<TableSkeleton rows={10} />} sx={styles.noDataContainer}>
      <Grid item sx={styles.container} ref={applyRef}>
        <Table stickyHeader>
          <MasterDocumentModalLinesTableHeader />

          <TableBody>
            {items?.map((item) => (
              <MasterDocumentModalLinesRow
                key={item.id}
                item={item}
                getCurrencyCode={getCurrencyCode}
                amountFormatter={amountFormatter}
                documentId={Number(documentId)}
                clientCostArticlesOptions={clientCostArticlesOptions}
              />
            ))}

            <TableRow sx={styles.totalRow}>
              <TableCell sx={styles.totalRowCell}>
                <Typography fontWeight="semiBold">
                  <FormattedMessage id="table.footer.total" />
                </Typography>
              </TableCell>

              {generateEmptyArray(7).map((_, index) => (
                <TableCell key={index} sx={styles.totalRowCell} />
              ))}

              <TableCell align="right" sticky right={160} shadow="dynamicLeft" sx={styles.totalRowCell} />

              <TableCell align="right" sx={styles.totalRowCell} sticky right={0}>
                <Typography fontFamily="mono" fontWeight="semiBold">
                  {amountFormatter(total)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </NoDataContainer>
  );
};

export default MasterDocumentModalLines;
