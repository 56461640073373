import { IDeleteInvoiceModalData } from '@/pages/backoffice/Modals/types';

export default class DeleteInvoiceModalData implements IDeleteInvoiceModalData {
  documentId: number;
  isDocumentAlreadyDigitized: boolean;

  constructor(documentId: number, isDocumentAlreadyDigitized: boolean) {
    this.documentId = documentId;
    this.isDocumentAlreadyDigitized = isDocumentAlreadyDigitized;
  }
}
