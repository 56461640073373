import State from '@/app/types/state';
import { getClientCostArticlesDictOptions } from '@/dictionary/selector';
import { createSelector } from 'reselect';
import SendToErpRequestInvoicesModalData from '../command/SendToErpRequestInvoicesModalData';

const getModalData = (state: State) => state.backoffice.modals.data as SendToErpRequestInvoicesModalData;
const getLoading = (state: State) => state.backoffice.requests.singleRequest.request.isERPLoading;

export default createSelector(
  [getClientCostArticlesDictOptions, getLoading, getModalData],
  (clientCostArticlesOptions, loading, modalData) => ({
    clientCostArticlesOptions,
    loading,
    requestId: modalData?.requestId,
    invoiceDocumentId: modalData?.invoiceDocumentId,
  })
);
