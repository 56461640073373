import { DEBOUNCE_PERIOD } from '@core-ui/consts';
import { updateQuery } from '@core-ui/url';
import { ISagaContext } from '@/app/types/common';
import { ILedgerFiltersState } from '@/pages/backoffice/Ledger/LedgerFilters/reducer';
import { debounce, getContext, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { getFilters as filterSelector } from './selector';

function* filterToQuerySaga() {
  const filters: ILedgerFiltersState = yield select(filterSelector);
  const history: ISagaContext['history'] = yield getContext('history');

  history.replace(updateQuery(filters));
}

export default [
  debounce(DEBOUNCE_PERIOD, actions.setTextFilter, filterToQuerySaga),
  takeLatest(actions.updateLedgerFiltersQuery, filterToQuerySaga),
];
