import { ApproveStatusEnum, DocumentSearchResult } from '@/generated';
import Button from '@core-ui/button';
import { COLOURS } from '@core-ui/styles';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { CheckCircle, Prohibit } from '@phosphor-icons/react';
import { FormattedMessage } from 'components/Intl';
import React from 'react';
import { useDispatch } from 'react-redux';
import { approveDocument, declineDocument } from '../../actions';
import useStyles from './styles';

interface IProps {
  document: Nullable<DocumentSearchResult>;
}

const ValidationButtons = (props: IProps) => {
  const { document } = props;

  const styles = useStyles({ status: document?.status });
  const dispatch = useDispatch();

  const handleApproveDocument = () => {
    if (document?.id) {
      dispatch(approveDocument({ documentId: document.id }));
    }
  };

  const handleDeclineDocument = () => {
    if (document?.id) {
      dispatch(declineDocument({ documentId: document.id }));
    }
  };

  const isApproveButtonsDisabled = document?.status !== ApproveStatusEnum.AWAITING;

  return (
    <Grid item container gap={2} width="auto" wrap="nowrap">
      <Tooltip
        title={
          isApproveButtonsDisabled ? (
            <FormattedMessage
              id="tooltip.document_has_already_been_approved_or_declined"
              values={{ status: document?.status }}
            />
          ) : (
            ''
          )
        }
        placement="top"
        arrow
      >
        <div>
          <Button
            variant="outlined"
            disabled={isApproveButtonsDisabled}
            onClick={handleApproveDocument}
            sx={styles.approveButton}
            label={<FormattedMessage id="label.approve" />}
            startIcon={
              <SvgIcon
                htmlColor={
                  document?.status === ApproveStatusEnum.DECLINED
                    ? COLOURS.Coconut.Solid[500]
                    : COLOURS.Avocado.Solid[600]
                }
              >
                <CheckCircle />
              </SvgIcon>
            }
          />
        </div>
      </Tooltip>

      <Tooltip
        title={
          isApproveButtonsDisabled ? (
            <FormattedMessage
              id="tooltip.document_has_already_been_approved_or_declined"
              values={{ status: document?.status }}
            />
          ) : (
            ''
          )
        }
        placement="top"
        arrow
      >
        <div>
          <Button
            variant="outlined"
            disabled={isApproveButtonsDisabled}
            onClick={handleDeclineDocument}
            sx={styles.declineButton}
            label={<FormattedMessage id="label.decline" />}
            startIcon={
              <SvgIcon
                htmlColor={
                  document?.status === ApproveStatusEnum.APPROVED
                    ? COLOURS.Coconut.Solid[500]
                    : COLOURS.Strawberry.Solid[600]
                }
              >
                <Prohibit />
              </SvgIcon>
            }
          />
        </div>
      </Tooltip>
    </Grid>
  );
};

export default ValidationButtons;
