import { getBudgetApproveFlowEnabled, vehicleIdSelector } from '@/app/selectors';
import { getAmountFormatterWithCurrencyCodeById } from '@/dictionary/selector';
import { getLedgerDocument } from '@/pages/backoffice/Ledger/LedgerDocumentRoot/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [getLedgerDocument, vehicleIdSelector, getAmountFormatterWithCurrencyCodeById, getBudgetApproveFlowEnabled],
  (transaction, boatId, currencyFormatter, budgetApproveFlowActive) => ({
    item: transaction.value ?? null,
    boatId,
    currencyFormatter,
    budgetApproveFlowActive,
  })
);
