import { SxProps, Theme } from '@mui/material/styles';
import { DEFAULT_TABLE_AUTOCOMPLETE_WIDTH } from '@/pages/backoffice/consts';
import { SINGLE_DOCUMENT_LINE_COLUMN_WIDTH } from '@/pages/backoffice/Ledger/consts';

export default (): Record<string, SxProps<Theme>> => ({
  lineNameCell: {
    maxWidth: SINGLE_DOCUMENT_LINE_COLUMN_WIDTH,
    overflow: 'hidden',
    overflowWrap: 'break-word',
    verticalAlign: 'top',
  },

  autocompleteCell: {
    maxWidth: DEFAULT_TABLE_AUTOCOMPLETE_WIDTH,
  },

  cell: {
    verticalAlign: 'top',
  },
});
