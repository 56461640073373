import { PUT } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { showNotification } from '@/components/Notification/actions';
import { ApproveStatusEnum, UpdateApprove } from '@/generated';
import { getLedgerDocument } from '@/pages/backoffice/Ledger/LedgerDocumentRoot/actions';
import { getLedgerList } from '@/pages/backoffice/Ledger/LedgerList/actions';
import { closeBackofficeModal, toggleModalLoading } from '@/pages/backoffice/Modals/actions';
import { Action } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* declineDocument({ payload }: Action<actions.IDeclineDocument>) {
  try {
    const { id, reloadDocumentList, declineReason } = payload;

    yield put(toggleModalLoading());

    yield call(PUT, '/approves', [
      {
        document_id: id,
        status: ApproveStatusEnum.DECLINED,
        reason: declineReason,
      },
    ] as UpdateApprove[]);

    if (reloadDocumentList) {
      yield put(getLedgerList(null));
    } else {
      yield put(getLedgerDocument(null));
    }

    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success.text.document_declined',
      })
    );
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(closeBackofficeModal());
  }
}

export default [takeLatest(actions.declineDocument, declineDocument)];
