import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { DocSortingColumns } from '@/generated';
import { DEFAULT_TABLE_AUTOCOMPLETE_WIDTH } from '@/pages/backoffice/consts';
import { ITableColumn } from '@/pages/backoffice/types';
import { ChartBar, File } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'components/Intl';
import {
  ACTIONS_CELL_WIDTH,
  VALIDATION_BUTTONS_CELL_WIDTH,
  VALIDATION_STATUS_CELL_WIDTH,
} from '@/pages/backoffice/Ledger/LedgerList/consts';

export const DEFAULT_HEADERS: ITableColumn[] = [
  {
    key: 'document_name',
    title: <FormattedMessage id="table.header.document_name" />,
    orderBy: DocSortingColumns.NUMBER,
    dataTestId: 'table-column-head-document-name',
    width: 320,
  },
  {
    key: 'vendor',
    title: <FormattedMessage id="table.header.vendor" />,
    orderBy: DocSortingColumns.VENDOR_ID,
    dataTestId: 'table-column-head-vendor',
    width: 202,
  },
  {
    key: 'document_type_icon',
    title: (
      <Tooltip title={<FormattedMessage id="label.document_type" />} placement="top" arrow>
        <SvgIcon>
          <File />
        </SvgIcon>
      </Tooltip>
    ),
    align: 'center',
    width: 46,
  },
  {
    key: 'upload_date',
    title: <FormattedMessage id="table.header.upload_date" />,
    orderBy: DocSortingColumns.CREATED_AT,
    dataTestId: 'table-column-head-created-at',
    width: 164,
  },
  {
    key: 'request',
    title: <FormattedMessage id="table.header.request" />,
    orderBy: DocSortingColumns.REQUEST_ID,
    dataTestId: 'table-column-head-request',
    width: DEFAULT_TABLE_AUTOCOMPLETE_WIDTH,
  },
  {
    key: 'uploaded_by',
    title: <FormattedMessage id="label.uploaded_by" />,
    orderBy: DocSortingColumns.UPLOADED_BY_ID,
    dataTestId: 'table-column-head-uploaded-by',
    width: 200,
  },
  {
    key: 'currency',
    title: (
      <Tooltip title={<FormattedMessage id="table.header.currency" />} placement="top" arrow>
        <div>
          <FormattedMessage id="table.header.currency_short" />
        </div>
      </Tooltip>
    ),
    width: 54,
  },
  {
    key: 'expenses',
    title: <FormattedMessage id="table.header.expenses" />,
    align: 'right',
    orderBy: DocSortingColumns.TOTAL_PRICE,
    dataTestId: 'table-column-head-expenses',
    width: 144,
  },
  {
    key: 'analytics_icon',
    title: (
      <Tooltip title={<FormattedMessage id="label.visibility_in_analytics" />} placement="top" arrow>
        <SvgIcon>
          <ChartBar />
        </SvgIcon>
      </Tooltip>
    ),
    align: 'center',
    width: 46,
  },
];

export const VALIDATION_BUTTONS_HEADERS: ITableColumn[] = [
  {
    key: 'validation_buttons',
    title: <FormattedMessage id="table.header.validation" />,
    width: VALIDATION_BUTTONS_CELL_WIDTH,
    align: 'right',
    orderBy: DocSortingColumns.APPROVED,
    right: ACTIONS_CELL_WIDTH,
    shadow: 'dynamicLeft',
  },
];

export const VALIDATION_BUTTONS_AND_LEVELS_HEADERS: ITableColumn[] = [
  {
    key: 'validation_status',
    title: <FormattedMessage id="table.header.approval_flow" />,
    width: VALIDATION_STATUS_CELL_WIDTH,
    right: VALIDATION_BUTTONS_CELL_WIDTH + ACTIONS_CELL_WIDTH,
    shadow: 'dynamicLeft',
  },
  {
    key: 'validation_buttons',
    title: <FormattedMessage id="table.header.validation" />,
    width: VALIDATION_BUTTONS_CELL_WIDTH,
    align: 'right',
    orderBy: DocSortingColumns.APPROVED,
    right: ACTIONS_CELL_WIDTH,
  },
];

export const ACTION_BUTTONS_HEADERS = (budgetApproveFlowActive?: boolean): ITableColumn[] => [
  {
    title: '',
    key: 'actions',
    width: ACTIONS_CELL_WIDTH,
    right: 0,
    shadow: budgetApproveFlowActive ? undefined : 'left',
  },
];
