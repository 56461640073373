import { extractFlattenPaths, Route } from '@core-ui/redux-router';
import { ROUTES } from '@/app/consts/routes';
import { getClientCostArticleDict, getCostArticleDict, getRequestDict, getVendorDict } from '@/dictionary/actions';
import { BACKOFFICE_TABS } from '@/pages/backoffice/consts';
import LedgerDocumentRoot from '@/pages/backoffice/Ledger/LedgerDocumentRoot';
import { getLedgerDocument, resetLedgerDocument } from '@/pages/backoffice/Ledger/LedgerDocumentRoot/actions';
import {
  resetLedgerFilters,
  setLedgerFiltersFromQuery,
  updateLedgerFiltersQuery,
} from '@/pages/backoffice/Ledger/LedgerFilters/actions';
import { getLedgerList, resetLedgerList } from '@/pages/backoffice/Ledger/LedgerList/actions';
import LedgerRoot from '@/pages/backoffice/Ledger/LedgerRoot';
import { resetApproveDocumentList } from '@/pages/backoffice/Modals/ApproveDocumentModal/actions';
import { IBackofficeState } from '@/pages/backoffice/reducer';
import { resetWalletsFilters } from '@/pages/backoffice/Wallets/WalletsFilters/actions';
import { getWalletsList, resetWalletsList } from '@/pages/backoffice/Wallets/WalletsList/actions';

const key: keyof IBackofficeState = 'ledger';

const routes: Route[] = [
  {
    key,
    exact: true,
    path: `/:boatId([0-9]+)/:route(${ROUTES.BACKOFFICE})/:backofficeTab(${BACKOFFICE_TABS.LEDGER})`,
    component: LedgerRoot,
    effects: [
      { events: ['once'], trigger: getRequestDict },
      { events: ['once'], trigger: getVendorDict },
      { events: ['once'], trigger: getClientCostArticleDict },
      { events: ['once'], trigger: getCostArticleDict },

      { events: ['on_enter'], trigger: resetLedgerList },
      { events: ['on_enter'], trigger: resetLedgerFilters },
      { events: ['on_enter'], trigger: resetApproveDocumentList },

      { events: ['on_enter'], trigger: setLedgerFiltersFromQuery },
      { events: ['on_enter'], trigger: updateLedgerFiltersQuery },
      { events: ['on_enter'], trigger: getLedgerList },

      // для фильтра по кошелькам в дровере More Filters
      { events: ['on_enter'], trigger: resetWalletsFilters },
      { events: ['on_enter'], trigger: resetWalletsList },
      { events: ['on_enter'], trigger: getWalletsList },
    ],
  },
  {
    key,
    exact: true,
    path: `/:boatId([0-9]+)/:route(${ROUTES.BACKOFFICE})/:backofficeTab(${BACKOFFICE_TABS.LEDGER})/:documentId`,
    component: LedgerDocumentRoot,
    effects: [
      { events: ['once'], trigger: getRequestDict },
      { events: ['once'], trigger: getClientCostArticleDict },

      { events: ['on_enter'], trigger: resetLedgerDocument },
      { events: ['on_enter'], trigger: getLedgerDocument },

      { events: ['on_enter'], trigger: resetWalletsFilters },
      { events: ['on_enter'], trigger: resetWalletsList },
      { events: ['on_enter'], trigger: getWalletsList },
    ],
  },
];

export const LEDGER_PATHS = extractFlattenPaths(routes);

export default routes;
