import State from '@/app/types/state';
import DeleteContractModalData from '@/pages/backoffice/Modals/command/DeleteContractModalData';
import { createSelector } from 'reselect';

const getModalsData = (state: State) => state.backoffice.modals.data as DeleteContractModalData;

const getLoading = (state: State) => state.backoffice.modals.loading;

export default createSelector([getModalsData, getLoading], (data, loading) => ({
  id: data?.id,
  shouldRedirect: data?.shouldRedirect,
  loading,
}));
