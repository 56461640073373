import { IDeleteContract } from '@/pages/backoffice/Contracts/ContractsList/actions';

export default class DeleteContractModalData implements IDeleteContract {
  id: number;
  shouldRedirect: boolean;

  constructor(id: number, shouldRedirect: boolean) {
    this.id = id;
    this.shouldRedirect = shouldRedirect;
  }
}
