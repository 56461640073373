import { combineReducers } from 'redux';
import lines, { IMasterDocumentModalLinesState } from './MasterDocumentModalLines/reducer';
import root, { IMasterDocumentModalRootState } from './MasterDocumentModalRoot/reducer';

export interface IMasterDocumentModalState {
  root: IMasterDocumentModalRootState;
  lines: IMasterDocumentModalLinesState;
}

export default combineReducers({
  root,
  lines,
});
