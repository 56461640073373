import { FileStatusEnum } from '@/generated';
import { IDeleteInboxDocument } from '@/pages/backoffice/Inbox/InboxList/InboxRow/actions';

export default class DeleteInboxDocumentData implements IDeleteInboxDocument {
  id: number;
  status: FileStatusEnum;

  constructor(id: number, status: FileStatusEnum) {
    this.id = id;
    this.status = status;
  }
}
