import { COLOURS } from '@core-ui/styles';
import { tableCellClasses, tableRowClasses } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  noDataContainer: {
    height: '100%',
    overflowY: 'auto',
  },

  container: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },

  totalRow: {
    [`&.${tableRowClasses.root}>.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
      backgroundColor: COLOURS.WHITE,
    },
    [`&.${tableRowClasses.root}:nth-of-type(even)>.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
      backgroundColor: COLOURS.WHITE,
    },
    [`&.${tableRowClasses.root}:hover>.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
      backgroundColor: COLOURS.WHITE,
    },
  },

  totalRowCell: {
    borderTop: `2px solid ${COLOURS.Coconut.Solid[300]}`,
  },
});
