import { Nullable } from '@core-ui/types';
import { WalletInputSchema, WalletOutputSchema, WalletUpdateSchema } from '@/generated';
import { createAction } from 'redux-actions';

export interface IWalletCreateEditModalData {
  walletData: Nullable<WalletOutputSchema>;
  shouldRedirect: boolean;
}

export const reducerName = 'backoffice.modals.wallets.create_edit_wallet';

export interface ISubmitCreateWalletForm {
  walletData: WalletInputSchema;
  shouldRedirect: boolean;
}

export interface ISubmitEditWalletForm {
  walletData: WalletUpdateSchema;
  shouldRedirect: boolean;
}

export const submitCreateWalletForm = createAction<ISubmitCreateWalletForm>(`${reducerName}.SUBMIT_CREATE_WALLET_FORM`);

export const submitEditWalletForm = createAction<ISubmitEditWalletForm>(`${reducerName}.SUBMIT_EDIT_WALLET_FORM`);
