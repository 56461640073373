import Modal from '@core-ui/modal';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import ImagesVerticalCarousel from 'components/ImagesVerticalCarousel';
import NoDataContainer from 'components/NoDataContainer';
import { SidePanelLeft } from 'components/SidePanel';
import { getClientCostArticleDict, getRequestDict } from 'dictionary/actions';
import { useAppSelector } from 'hooks/useAppSelector';
import {
  MASTER_DOCUMENT_PREVIEW_PANEL_DEFAULT_WIDTH,
  MASTER_DOCUMENT_PREVIEW_PANEL_MAX_WIDTH,
  MASTER_DOCUMENT_PREVIEW_PANEL_MIN_WIDTH,
} from 'pages/backoffice/Modals/MasterDocumentModal/consts';
import MasterDocumentModalContent from 'pages/backoffice/Modals/MasterDocumentModal/MasterDocumentModalContent';
import {
  getMasterDocumentModalDocumentLines,
  resetMasterDocumentModalLines,
} from 'pages/backoffice/Modals/MasterDocumentModal/MasterDocumentModalLines/actions';
import MasterDocumentModalTabs from 'pages/backoffice/Modals/MasterDocumentModal/MasterDocumentModalTabs';
import { resetWalletsFilters } from 'pages/backoffice/Wallets/WalletsFilters/actions';
import { getWalletsList, resetWalletsList } from 'pages/backoffice/Wallets/WalletsList/actions';
import React, { useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import MasterDocumentModalHeader from '../MasterDocumentModalHeader';
import { getMasterDocument, resetMasterDocument } from './actions';
import selector, { getNoDataContainerData } from './selector';
import useStyles from './styles';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const MasterDocumentModalRoot = (props: IProps) => {
  const { opened, onClose } = props;
  const { documentId, imageLinks } = useAppSelector(selector);

  const styles = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (opened) {
      batch(() => {
        // dictionaries required by Information tab
        dispatch(resetWalletsFilters());
        dispatch(resetWalletsList());
        dispatch(getWalletsList(null));
        dispatch(getClientCostArticleDict());
        dispatch(getRequestDict());

        dispatch(resetMasterDocument());
        dispatch(resetMasterDocumentModalLines());
        dispatch(getMasterDocument({ documentId }));
        dispatch(getMasterDocumentModalDocumentLines({ documentId }));
      });
    }
  }, [opened, documentId]);

  return (
    <Modal open={opened} maxWidth="xl" onClose={onClose} header={<MasterDocumentModalHeader onClose={onClose} />}>
      <DialogContent sx={styles.dialogContent}>
        <Grid item container height="100%" wrap="nowrap">
          <SidePanelLeft
            defaultWidth={MASTER_DOCUMENT_PREVIEW_PANEL_DEFAULT_WIDTH}
            minWidth={MASTER_DOCUMENT_PREVIEW_PANEL_MIN_WIDTH}
            maxWidth={MASTER_DOCUMENT_PREVIEW_PANEL_MAX_WIDTH}
          >
            <ImagesVerticalCarousel
              documentId={documentId}
              imageLinks={imageLinks}
              defaultWidth={MASTER_DOCUMENT_PREVIEW_PANEL_DEFAULT_WIDTH}
            />
          </SidePanelLeft>

          <NoDataContainer
            selector={getNoDataContainerData}
            direction="column"
            wrap="nowrap"
            overflow="hidden"
            paddingBottom={11}
          >
            <MasterDocumentModalTabs />
            <MasterDocumentModalContent />
          </NoDataContainer>
        </Grid>
      </DialogContent>
    </Modal>
  );
};

export default MasterDocumentModalRoot;
