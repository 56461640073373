import { createSelector } from 'reselect';

import State from '@/app/types/state';
import { getCostCentersDictOptions, getCurrencyDictOptions, getDepartmentsDictOptions } from '@/dictionary/selector';
import { INVOICE_FILE_UPLOAD_TYPE, INVOICE_FILE_UPLOAD_TYPE_ENUM } from '@/pages/backoffice/Requests/types';

export const invoiceTypeOptions = INVOICE_FILE_UPLOAD_TYPE.map((type) => ({
  label: type === INVOICE_FILE_UPLOAD_TYPE_ENUM.PREPAYMENT ? 'Pre-payment' : 'Post-payment',
  value: type,
}));

const loadingSelector = (state: State) => state.backoffice.modals.loading;

export default createSelector(
  [getDepartmentsDictOptions, getCurrencyDictOptions, getCostCentersDictOptions, loadingSelector],
  (departmentsOptions, currenciesOptions, ccOptions, loading) => ({
    departmentsOptions,
    currenciesOptions,
    ccOptions,
    loading,
  })
);
