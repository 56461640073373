import Button from '@core-ui/button';
import Modal from '@core-ui/modal';
import { COLOURS } from '@core-ui/styles';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import NoDataContainer from '@/components/NoDataContainer';
import { useAppSelector } from '@/hooks/useAppSelector';
import {
  approveDocument,
  getApproveDocumentList,
  resetApproveDocumentList,
} from '@/pages/backoffice/Modals/ApproveDocumentModal/actions';
import ApproveDocumentModalListRow from '@/pages/backoffice/Modals/ApproveDocumentModal/components/ApproveDocumentModalListRow/ApproveDocumentModalListRow';
import {
  ApproveDocumentModalBadgesSkeleton,
  ApproveDocumentModalButtonsSkeleton,
  ApproveDocumentModalTableSkeleton,
} from '@/pages/backoffice/Modals/ApproveDocumentModal/components/skeletons';
import TableHeader from '@/pages/backoffice/Modals/ApproveDocumentModal/components/TableHeader/TableHeader';
import { Check, Info } from '@phosphor-icons/react';
import isNil from 'lodash-es/isNil';
import React, { FC, useEffect } from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import selector, {
  getNoDataBadgesContainerData,
  getNoDataButtonsContainerData,
  getNoDataTableContainerData,
} from './selector';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const budgetCardStyles: SxProps<Theme> = {
  background: COLOURS.Coconut.Solid[100],
};

const ApproveDocumentModal: FC<IProps> = (props) => {
  const { opened, onClose } = props;

  const dispatch = useDispatch();
  const {
    id,
    reloadDocumentList,
    loading,
    documentTotal,
    totalRemaining,
    items,
    costArticleMappingEnabled,
    amountFormatter,
  } = useAppSelector(selector);

  useEffect(() => {
    if (opened) {
      dispatch(getApproveDocumentList({ id }));
    }
  }, [opened]);

  const handleClose = () => {
    dispatch(resetApproveDocumentList());
    onClose();
  };

  const handleConfirm = () => {
    dispatch(approveDocument({ id, reloadDocumentList }));
  };

  return (
    <Modal
      open={opened}
      maxWidth="sm"
      onClose={handleClose}
      title={<FormattedMessage id="modal.backoffice.approve_document.title" />}
      subtitle={<FormattedMessage id="modal.backoffice.approve_document.subtitle" />}
    >
      <DialogContent>
        <Grid container gap={6}>
          <NoDataContainer
            container
            gap={3}
            selector={getNoDataBadgesContainerData}
            loader={<ApproveDocumentModalBadgesSkeleton />}
          >
            <Grid
              item
              container
              direction="column"
              gap={1}
              width={200}
              height={70}
              padding={3}
              borderRadius={1}
              sx={budgetCardStyles}
            >
              <Typography fontFamily="mono" fontWeight="semiBold" fontSize="large">
                {amountFormatter(documentTotal)}
              </Typography>

              <Typography color="text.secondary" fontSize="smaller">
                <FormattedMessage id="label.document_amount" />
              </Typography>
            </Grid>

            {!isNil(totalRemaining) && (
              <Grid
                item
                container
                direction="column"
                gap={1}
                width={200}
                height={70}
                padding={3}
                borderRadius={1}
                sx={budgetCardStyles}
              >
                <Typography fontFamily="mono" fontWeight="semiBold" fontSize="large">
                  {amountFormatter(totalRemaining)}
                </Typography>

                <Typography color="text.secondary" fontSize="smaller">
                  <FormattedMessage id="label.remaining_budget" />
                </Typography>
              </Grid>
            )}
          </NoDataContainer>

          <NoDataContainer
            selector={getNoDataTableContainerData}
            loader={<ApproveDocumentModalTableSkeleton />}
            noDataContent={<div />}
          >
            <Table stickyHeader>
              <TableHeader />

              <TableBody>
                {items?.map((item, id) => (
                  <ApproveDocumentModalListRow key={id} item={item} amountFormatter={amountFormatter} />
                ))}
              </TableBody>
            </Table>
          </NoDataContainer>
        </Grid>

        <NoDataContainer
          container
          component={DialogActions}
          justifyContent="space-between"
          alignItems="center"
          disableSpacing
          selector={getNoDataButtonsContainerData}
          loader={<ApproveDocumentModalButtonsSkeleton />}
        >
          {costArticleMappingEnabled && (
            <Grid item container width="auto" gap={2}>
              <Tooltip title={<FormattedMessage id="tooltip.use_provided_chart_of_accounts" />} placement="top" arrow>
                <SvgIcon htmlColor={COLOURS.Coconut.Solid[600]}>
                  <Info />
                </SvgIcon>
              </Tooltip>

              <Typography component="span">
                <FormattedMessage id="modal.backoffice.approve_document.using_client_categories" />
              </Typography>
            </Grid>
          )}

          <Grid item container width="auto" marginLeft="auto" gap={3}>
            <Button
              color="blue"
              size="large"
              variant="outlined"
              onClick={onClose}
              disabled={loading}
              label={<FormattedMessage id="label.cancel" />}
            />

            <Button
              color="blue"
              size="large"
              variant="contained"
              autoFocus
              onClick={handleConfirm}
              loading={loading}
              disabled={loading}
              label={<FormattedMessage id="label.approve" />}
              startIcon={<Check />}
            />
          </Grid>
        </NoDataContainer>
      </DialogContent>
    </Modal>
  );
};

export default ApproveDocumentModal;
