import Button from '@core-ui/button';
import Modal from '@core-ui/modal';
import { COLOURS } from '@core-ui/styles';
import Collapse from '@mui/material/Collapse';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import ImagesCarousel from 'components/ImagesCarousel';
import { useIntl } from 'components/Intl';
import { useAppSelector } from '@/hooks/useAppSelector';
import SourceFileComments from '@/pages/backoffice/Modals/ImageCarouselModal/SourceFileComments';
import { Sidebar } from '@phosphor-icons/react';
import React, { FC, useState } from 'react';
import selector from './selector';
import useStyles from './styles';

const collapseStyle: SxProps<Theme> = {
  flexShrink: 0,
  height: '100%',
  padding: '30px 0 !important',
};

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const ImageCarouselModal: FC<IProps> = (props) => {
  const { opened, onClose } = props;

  const styles = useStyles();
  const intl = useIntl();

  const { pages, initialPage } = useAppSelector(selector);
  const [showComments, setShowComments] = useState(true);

  const toggleShowComments = () => {
    setShowComments((show) => !show);
  };

  const additionalControls = (
    <Button
      onClick={toggleShowComments}
      variant="text"
      aria-label={intl.formatMessage({ id: 'aria.label.toggle_comments' })}
      icon={
        <SvgIcon htmlColor={COLOURS.WHITE}>
          <Sidebar />
        </SvgIcon>
      }
    />
  );

  return (
    <Modal open={opened} paperStyles={styles.paper} onClose={onClose} header={<></>}>
      <DialogContent sx={styles.dialogContent}>
        <Grid container alignItems="center" justifyContent="center" height="100%">
          <Grid container flexWrap="nowrap" height="100%" maxHeight="100%" alignItems="center" justifyContent="center">
            <ImagesCarousel
              pages={pages}
              initialPage={initialPage}
              showControls
              additionalControls={additionalControls}
              onClose={!showComments ? onClose : undefined}
            />

            <Collapse in={showComments} orientation="horizontal" sx={collapseStyle}>
              <SourceFileComments onClose={onClose} />
            </Collapse>
          </Grid>
        </Grid>
      </DialogContent>
    </Modal>
  );
};

export default ImageCarouselModal;
