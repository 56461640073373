import { DELETE, PATCH } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { showNotification } from '@/components/Notification/actions';
import { DocPatchInputSchema } from '@/generated';
import { getLedgerDocument } from '@/pages/backoffice/Ledger/LedgerDocumentRoot/actions';
import { IUpdateRequest } from '@/pages/backoffice/Ledger/LedgerList/LedgerListRow/actions';
import { Action } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { IUpdateErpCategory, IUpdateWallet } from './actions';

function* updateErpCategory({ payload }: Action<IUpdateErpCategory>) {
  try {
    yield put(actions.setDocumentInfoLoading({ loading: true }));

    yield call(PATCH, `/document/${payload.documentId}`, {
      xero_category: payload.categoryId,
    } as DocPatchInputSchema);

    yield put(getLedgerDocument(null));
    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success.text.erp_update',
      })
    );
  } catch (e) {
    yield put(
      showNotification({
        variant: 'error',
        titleId: 'notification.error.text.erp_update',
      })
    );
  } finally {
    yield put(actions.setDocumentInfoLoading({ loading: false }));
  }
}

function* deleteErpCategory({ payload }: Action<actions.IDeleteErpCategory>) {
  try {
    yield put(actions.setDocumentInfoLoading({ loading: true }));

    yield call(DELETE, `/document/${payload.documentId}/xero_category`);

    yield put(getLedgerDocument(null));
    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success.text.erp_delete',
      })
    );
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(actions.setDocumentInfoLoading({ loading: false }));
  }
}

function* updateWallet({ payload }: Action<IUpdateWallet>) {
  try {
    yield put(actions.setDocumentInfoLoading({ loading: true }));

    yield call(PATCH, `/document/${payload.documentId}`, {
      wallet_id: payload.walletId,
    } as DocPatchInputSchema);

    yield put(getLedgerDocument(null));

    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success.text.wallet_update',
      })
    );
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(actions.setDocumentInfoLoading({ loading: false }));
  }
}

function* deleteWallet({ payload }: Action<actions.IDeleteWallet>) {
  try {
    yield put(actions.setDocumentInfoLoading({ loading: true }));

    yield call(DELETE, `/document/${payload.documentId}/wallet`);

    yield put(getLedgerDocument(null));

    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success.text.wallet_delete',
      })
    );
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(actions.setDocumentInfoLoading({ loading: false }));
  }
}

function* updateRequest({ payload }: Action<IUpdateRequest>) {
  try {
    yield put(actions.setDocumentInfoLoading({ loading: true }));

    yield call(PATCH, `/document/${payload.documentId}`, {
      request_id: payload.requestId,
    } as DocPatchInputSchema);

    yield put(getLedgerDocument(null));

    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success.text.request_update',
      })
    );
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(actions.setDocumentInfoLoading({ loading: false }));
  }
}

function* deleteRequest({ payload }: Action<actions.IDeleteRequest>) {
  try {
    yield put(actions.setDocumentInfoLoading({ loading: true }));

    yield call(DELETE, `/document/${payload.documentId}/request`);

    yield put(getLedgerDocument(null));

    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success.text.request_delete',
      })
    );
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(actions.setDocumentInfoLoading({ loading: false }));
  }
}

function* updateVisibilityInAnalytics({ payload }: Action<actions.IUpdateVisibilityInAnalytics>) {
  try {
    yield put(actions.setDocumentInfoLoading({ loading: true }));

    yield call(PATCH, `/document/${payload.documentId}`, {
      hide_from_analytics: payload.hide,
    } as DocPatchInputSchema);

    yield put(getLedgerDocument(null));

    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success.text.visibility_in_analytics_update',
      })
    );
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(actions.setDocumentInfoLoading({ loading: false }));
  }
}

export default [
  takeLatest(actions.updateErpCategory, updateErpCategory),
  takeLatest(actions.deleteErpCategory, deleteErpCategory),
  takeLatest(actions.updateWallet, updateWallet),
  takeLatest(actions.deleteWallet, deleteWallet),
  takeLatest(actions.updateRequest, updateRequest),
  takeLatest(actions.deleteRequest, deleteRequest),
  takeLatest(actions.updateVisibilityInAnalytics, updateVisibilityInAnalytics),
];
