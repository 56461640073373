import { POST } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { vehicleIdSelector } from '@/app/selectors';
import { showNotification } from '@/components/Notification/actions';
import { XeroInputSchema, XeroRedirectSchema } from '@/generated';
import { closeBackofficeModal, toggleModalLoading } from '@/pages/backoffice/Modals/actions';
import { ISendToXeroItemModal } from '@/pages/backoffice/Modals/types';
import { Action } from 'redux-actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* sendToERP({ payload }: Action<ISendToXeroItemModal>) {
  try {
    yield put(toggleModalLoading());

    const { invoiceCode, documentId, walletId, requestId } = payload;

    const boatId: number = yield select(vehicleIdSelector);

    const response: XeroRedirectSchema = yield call(POST, '/xero/invoice/send', {
      boat_id: boatId,
      document_id: documentId,
      invoice_code: invoiceCode,
      wallet_id: walletId,
      request_id: requestId,
    } as XeroInputSchema);

    if ('login_redirect_uri' in response) {
      window.location.href = response.login_redirect_uri;
    }

    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success',
      })
    );
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(closeBackofficeModal());
  }
}

export default [takeLatest(actions.sendDocumentToErp, sendToERP)];
