import { DEFAULT_TABLE_AUTOCOMPLETE_WIDTH } from '@/pages/backoffice/consts';
import { COLOURS } from '@core-ui/styles';
import { tableCellClasses, tableRowClasses } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { MASTER_DOCUMENT_LINE_TAB_NAME_COLUMN_WIDTH } from 'pages/backoffice/Modals/MasterDocumentModal/consts';

export default (): Record<string, SxProps<Theme>> => ({
  row: {
    [`&.${tableRowClasses.root}>.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
      backgroundColor: COLOURS.WHITE,
    },
    [`&.${tableRowClasses.root}:nth-of-type(even)>.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
      backgroundColor: COLOURS.WHITE,
    },
    [`&.${tableRowClasses.root}:hover>.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
      backgroundColor: COLOURS.WHITE,
    },
  },

  lineNameCell: {
    maxWidth: MASTER_DOCUMENT_LINE_TAB_NAME_COLUMN_WIDTH,
    overflow: 'hidden',
    overflowWrap: 'break-word',
    verticalAlign: 'top',
  },

  autocompleteCell: {
    maxWidth: DEFAULT_TABLE_AUTOCOMPLETE_WIDTH,
  },

  cell: {
    verticalAlign: 'top',
  },
});
