import { SxProps, Theme } from '@mui/material/styles';
import { MASTER_DOCUMENT_HEADER_HEIGHT, MODAL_OUTER_MARGIN } from 'pages/backoffice/Modals/MasterDocumentModal/consts';

export default (): Record<string, SxProps<Theme>> => ({
  dialogContent: {
    padding: 0,
    overflow: 'hidden',
    height: `calc(100vh - ${MASTER_DOCUMENT_HEADER_HEIGHT + MODAL_OUTER_MARGIN * 2}px)`,
  },
});
