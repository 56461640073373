import masterDocumentHeaderSagas from './MasterDocumentModalHeader/sagas';
import masterDocumentInformationSagas from './MasterDocumentModalInfo/sagas';
import masterDocumentRootSagas from './MasterDocumentModalRoot/sagas';
import masterDocumentLinesSagas from './MasterDocumentModalLines/sagas';

export default [
  ...masterDocumentRootSagas,
  ...masterDocumentHeaderSagas,
  ...masterDocumentInformationSagas,
  ...masterDocumentLinesSagas,
];
