import { getBudgetApproveFlowEnabled, getLevelUserApprovalEnabled } from '@/app/selectors';
import State from '@/app/types/state';
import { NoDataContainerSelector } from '@/components/NoDataContainer/NoDataContainer';
import { createSelector } from 'reselect';

const getDocuments = (state: State) => state.backoffice.ledger.list;
const getLedgerFilters = (state: State) => state.backoffice.ledger.filters;

export const getNoDataContainerData: NoDataContainerSelector = createSelector([getDocuments], (state) => ({
  loading: state.loading,
  loaded: state.loaded,
  hasData: state.hasData,
  error: state.error,
}));

const getChunkLoading = (state: State) => state.backoffice.ledger.list.chunkLoading;
const getAllDataReceived = (state: State) => state.backoffice.ledger.list.isAllDataReceived;

export const chunkLoaderSelector = createSelector(
  [getChunkLoading, getAllDataReceived],
  (isChunkLoading, isAllDataReceived) => ({
    isChunkLoading,
    isAllDataReceived,
  })
);

export const getDocumentsItems = (state: State) => state.backoffice.ledger.list.value;

const getOrderBy = (state: State) => state.backoffice.ledger.list.orderBy;
const getOrder = (state: State) => state.backoffice.ledger.list.order;

export const getLedgerSortOrder = createSelector([getOrderBy, getOrder], (orderBy, order) => ({
  orderBy,
  order,
}));

export const getHeaderData = createSelector(
  [getLedgerSortOrder, getBudgetApproveFlowEnabled, getLevelUserApprovalEnabled],
  (sortOrder, budgetApproveFlowActive, levelUserApprovalEnabled) => ({
    orderBy: sortOrder.orderBy,
    order: sortOrder.order,
    budgetApproveFlowActive,
    levelUserApprovalEnabled,
  })
);

export const getPaginationPage = (state: State) => state.backoffice.ledger.list.paginationPage;

export const getFiltersApplied = createSelector([getLedgerFilters], (filters) =>
  Object.values(filters).some((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }

    return Boolean(value);
  })
);
