import reducer, { List } from '@core-ui/reducers_handlers';
import { Nullable } from '@core-ui/types';
import { BudgetRemainingTotal, CARemainingDocLine } from '@/generated';
import { handleActions } from 'redux-actions';
import Immutable from '@core-ui/immutable';
import * as actions from './actions';

export interface IApproveDocumentList extends List<CARemainingDocLine> {
  totalRemaining: Nullable<BudgetRemainingTotal>;
  documentTotalPrice: Nullable<number>;
}

const defaultState = Immutable<IApproveDocumentList>({
  loaded: false,
  hasData: false,
  loading: false,
  chunkLoading: false,
  isAllDataReceived: false,
  documentTotalPrice: null,
  totalRemaining: null,
});

export default handleActions<typeof defaultState, any>(
  {
    [actions.setDocumentTotalPrice.toString()]: (state, { payload }) => {
      return state.set('documentTotalPrice', payload);
    },
    [actions.setTotalRemaining.toString()]: (state, { payload }) => {
      return state.set('totalRemaining', payload);
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
