import { exhaustiveGuard } from '@/app/utils/app';
import React from 'react';

import BaseVariant from '@/pages/backoffice/Modals/CreateEditRequestModal/components/BaseVariant';
import { IRequestForm } from '@/pages/backoffice/Modals/CreateEditRequestModal/types';
import { PAYMENT_REQUEST_TYPE_ENUM } from '@/pages/backoffice/Requests/types';
import { Nullable } from '@core-ui/types';

interface IProps {
  selected: PAYMENT_REQUEST_TYPE_ENUM;
  formInitial: Nullable<IRequestForm>;
}

const CreateEditRequestContent: React.FC<IProps> = (props) => {
  const { selected, formInitial } = props;

  switch (selected) {
    case PAYMENT_REQUEST_TYPE_ENUM.DEFAULT:
    case PAYMENT_REQUEST_TYPE_ENUM.EMERGENCY:
    case PAYMENT_REQUEST_TYPE_ENUM.CASH:
    case PAYMENT_REQUEST_TYPE_ENUM.SALARY:
      return <BaseVariant reqType={selected} formInitial={formInitial} />;
    default:
      return exhaustiveGuard(selected);
  }
};

export default CreateEditRequestContent;
