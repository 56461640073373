import State from 'app/types/state';
import { NoDataContainerSelector } from 'components/NoDataContainer/NoDataContainer';
import { getAmountFormatter, getClientCostArticlesDictOptions, getCurrencyCodeById } from 'dictionary/selector';
import { getMasterDocumentRootValue } from 'pages/backoffice/Modals/MasterDocumentModal/MasterDocumentModalRoot/selector';
import { createSelector } from 'reselect';

const getLinesState = (state: State) => state.backoffice.masterDocumentModal.lines;

const getLinesTotal = createSelector([getLinesState], (linesState) =>
  linesState.value?.reduce<number>((total, currentValue) => {
    if (currentValue.total) {
      return total + currentValue.total;
    }

    return total;
  }, 0)
);

export const getNoDataContainerData: NoDataContainerSelector = createSelector([getLinesState], (lines) => ({
  loading: lines.loading,
  loaded: lines.loaded,
  hasData: lines.hasData,
  error: lines.error,
}));

export default createSelector(
  [
    getLinesState,
    getLinesTotal,
    getAmountFormatter,
    getCurrencyCodeById,
    getClientCostArticlesDictOptions,
    getMasterDocumentRootValue,
  ],
  (lines, total, amountFormatter, getCurrencyCode, clientCostArticlesOptions, document) => ({
    items: lines.value,
    total,
    amountFormatter,
    getCurrencyCode,
    clientCostArticlesOptions,
    documentId: document?.id,
  })
);
