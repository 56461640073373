import { useAppSelector } from '@/hooks/useAppSelector';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  AddQuotesToCompareModal,
  ApproveDocumentModal,
  CreateEditContractModal,
  CreateEditRequestModal,
  CreateEditWalletModal,
  DeclineDocumentModal,
  DeleteContractModal,
  DeleteInboxDocumentModal,
  DeleteInvoiceModal,
  DeleteRequestModal,
  DeleteSingleContractTransactionModal,
  DeleteWalletModal,
  DeleteWalletTransactionModal,
  ImageCarouselModal,
  RejectRequestModal,
  SendForApprovalModal,
  SendToErpModal,
  SendToErpRequestInvoicesBatchModal,
  SendToErpRequestInvoicesModal,
  UploadInvoicesModal,
  UploadQuotesModal,
  UseQuoteAsInvoiceModal,
  MasterDocumentModal,
} from '.';
import { closeBackofficeModal } from './actions';
import {
  AddQuotesToCompareModalData,
  ApproveDocumentModalData,
  CreateEditContractModalData,
  CreateEditRequestModalData,
  CreateEditWalletModalData,
  DeclineDocumentModalData,
  DeleteContractModalData,
  DeleteInboxDocumentData,
  DeleteInvoiceModalData,
  DeleteRequestModalData,
  DeleteSingleContractTransactionModalData,
  DeleteWalletModalData,
  DeleteWalletTransactionModalData,
  ImageCarouselModalData,
  MasterDocumentModalData,
  RejectRequestModalData,
  SendForApprovalModalData,
  SendToErpRequestInvoicesBatchModalData,
  SendToErpRequestInvoicesModalData,
  SendToXeroItemModalData,
  UploadInvoicesModalData,
  UploadQuotesModalData,
  UseQuoteAsInvoiceModalData,
} from './command';
import selector from './selector';

const BackofficeModals: FC = () => {
  const dispatch = useDispatch();
  const state = useAppSelector(selector);

  const handleClose = useCallback(() => dispatch(closeBackofficeModal()), [dispatch]);

  return (
    <>
      <AddQuotesToCompareModal
        opened={state.opened && state.data instanceof AddQuotesToCompareModalData}
        onClose={handleClose}
      />

      <ApproveDocumentModal
        opened={state.opened && state.data instanceof ApproveDocumentModalData}
        onClose={handleClose}
      />

      <CreateEditContractModal
        opened={state.opened && state.data instanceof CreateEditContractModalData}
        onClose={handleClose}
      />

      <CreateEditRequestModal
        opened={state.opened && state.data instanceof CreateEditRequestModalData}
        onClose={handleClose}
      />

      <CreateEditWalletModal
        opened={state.opened && state.data instanceof CreateEditWalletModalData}
        onClose={handleClose}
      />

      <DeclineDocumentModal
        opened={state.opened && state.data instanceof DeclineDocumentModalData}
        onClose={handleClose}
      />

      <DeleteContractModal
        opened={state.opened && state.data instanceof DeleteContractModalData}
        onClose={handleClose}
      />

      <DeleteInboxDocumentModal
        opened={state.opened && state.data instanceof DeleteInboxDocumentData}
        onClose={handleClose}
      />

      <DeleteInvoiceModal opened={state.opened && state.data instanceof DeleteInvoiceModalData} onClose={handleClose} />

      <DeleteRequestModal opened={state.opened && state.data instanceof DeleteRequestModalData} onClose={handleClose} />

      <DeleteSingleContractTransactionModal
        opened={state.opened && state.data instanceof DeleteSingleContractTransactionModalData}
        onClose={handleClose}
      />

      <DeleteWalletModal opened={state.opened && state.data instanceof DeleteWalletModalData} onClose={handleClose} />

      <DeleteWalletTransactionModal
        opened={state.opened && state.data instanceof DeleteWalletTransactionModalData}
        onClose={handleClose}
      />

      <ImageCarouselModal opened={state.opened && state.data instanceof ImageCarouselModalData} onClose={handleClose} />

      <RejectRequestModal opened={state.opened && state.data instanceof RejectRequestModalData} onClose={handleClose} />

      <SendForApprovalModal
        opened={state.opened && state.data instanceof SendForApprovalModalData}
        onClose={handleClose}
      />

      <SendToErpModal opened={state.opened && state.data instanceof SendToXeroItemModalData} onClose={handleClose} />

      <SendToErpRequestInvoicesBatchModal
        opened={state.opened && state.data instanceof SendToErpRequestInvoicesBatchModalData}
        onClose={handleClose}
      />

      <SendToErpRequestInvoicesModal
        opened={state.opened && state.data instanceof SendToErpRequestInvoicesModalData}
        onClose={handleClose}
      />

      <UploadInvoicesModal
        opened={state.opened && state.data instanceof UploadInvoicesModalData}
        onClose={handleClose}
      />

      <UploadQuotesModal opened={state.opened && state.data instanceof UploadQuotesModalData} onClose={handleClose} />

      <UseQuoteAsInvoiceModal
        opened={state.opened && state.data instanceof UseQuoteAsInvoiceModalData}
        onClose={handleClose}
      />

      <MasterDocumentModal
        opened={state.opened && state.data instanceof MasterDocumentModalData}
        onClose={handleClose}
      />
    </>
  );
};

export default BackofficeModals;
