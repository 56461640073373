import Grid from '@mui/material/Grid';
import { useAppSelector } from 'hooks/useAppSelector';
import { MASTER_DOCUMENT_TAB_ENUM } from 'pages/backoffice/Modals/MasterDocumentModal/consts';
import MasterDocumentModalInfo from 'pages/backoffice/Modals/MasterDocumentModal/MasterDocumentModalInfo';
import MasterDocumentModalLines from 'pages/backoffice/Modals/MasterDocumentModal/MasterDocumentModalLines';
import { getMasterDocumentTab } from 'pages/backoffice/Modals/MasterDocumentModal/MasterDocumentModalRoot/selector';
import React from 'react';

const MasterDocumentModalContent = () => {
  const tab = useAppSelector(getMasterDocumentTab);

  let content: JSX.Element;

  switch (tab) {
    case MASTER_DOCUMENT_TAB_ENUM.DOCUMENT:
      content = <MasterDocumentModalInfo />;
      break;
    case MASTER_DOCUMENT_TAB_ENUM.LINES:
      content = <MasterDocumentModalLines />;
      break;
    default:
      content = <MasterDocumentModalInfo />;
  }

  return (
    <Grid item container paddingTop={8} paddingX={5} overflow="auto" wrap="nowrap">
      {content}
    </Grid>
  );
};

export default MasterDocumentModalContent;
