import { vehicleIdSelector } from '@/app/selectors';
import State from '@/app/types/state';
import { getClientCostArticlesDictOptions, getRequestsDictOptions } from '@/dictionary/selector';
import { getLedgerDocument } from '@/pages/backoffice/Ledger/LedgerDocumentRoot/selector';
import { getWalletsOptions } from '@/pages/backoffice/Wallets/WalletsList/selector';
import { createSelector } from 'reselect';

const getLoading = (state: State) => state.backoffice.ledger.documentInformation.loading;

export default createSelector(
  [
    getLedgerDocument,
    vehicleIdSelector,
    getClientCostArticlesDictOptions,
    getWalletsOptions,
    getRequestsDictOptions,
    getLoading,
  ],
  (ledgerDocument, boatId, clientCostArticlesOptions, walletOptions, requestOptions, loading) => ({
    item: ledgerDocument.value,
    boatId,
    clientCostArticlesOptions,
    walletOptions,
    requestOptions,
    loading,
  })
);
