import {
  getMasterDocumentRootValue,
  getMasterDocumentTab,
} from 'pages/backoffice/Modals/MasterDocumentModal/MasterDocumentModalRoot/selector';
import { createSelector } from 'reselect';

export default createSelector([getMasterDocumentTab, getMasterDocumentRootValue], (tab, document) => ({
  tab,
  documentId: document?.id,
}));
