import State from '@/app/types/state';
import { createSelector } from 'reselect';

const getLoading = (state: State) => {
  const requestId = (state.backoffice.modals.data as Record<string, any>)?.requestId;

  return requestId ? state.backoffice.requests.singleRequest.request.loadingItems?.[requestId] : false;
};

export default createSelector([getLoading], (loading) => ({
  loading,
}));
