import { createAction } from 'redux-actions';

export const reducerName = 'modals.master_document.header';

export interface IApproveDeclineDocument {
  documentId: number;
}

export const approveDocument = createAction<IApproveDeclineDocument>(`${reducerName}.APPROVE_DOCUMENT`);
export const declineDocument = createAction<IApproveDeclineDocument>(`${reducerName}.DECLINE_DOCUMENT`);
