import State from '@/app/types/state';
import { getClientCostArticlesDictOptions } from '@/dictionary/selector';
import { createSelector } from 'reselect';
import SendForApprovalModalData from '../command/SendForApprovalModalData';

const getModalData = (state: State) => state.backoffice.modals.data as SendForApprovalModalData;

const getLoading = (state: State) => state.backoffice.modals.loading;

export default createSelector(
  [getClientCostArticlesDictOptions, getLoading, getModalData],
  (clientCostArticlesOptions, loading, modalData) => ({
    clientCostArticlesOptions,
    loading,
    invoiceCode: modalData?.invoiceCode,
    documentId: modalData?.documentId,
  })
);
