import { Nullable } from '@core-ui/types';
import { ContractInputSchema, ContractOutputSchema } from '@/generated';
import { createAction } from 'redux-actions';

export const reducerName = 'backoffice.modals.contracts.create_edit_contract';

export interface ICreateEditContractModalData {
  initialContractData: Nullable<ContractOutputSchema>;
  shouldRedirect: boolean;
  refetchSinglePage?: boolean;
}

interface IFormNotification {
  notify_every_days: number;
  notify_base_date: Date;
}

export interface IContractFormData
  extends Omit<ContractInputSchema, 'expires_at' | 'payment_date' | 'amount' | 'notification'> {
  expires_at: Date;
  payment_date: Date;
  amount: string;

  files: File[];
  id?: number;
  notification_active: boolean;
  notification: IFormNotification;
}

export interface ISubmitCreateContractForm {
  contractData: IContractFormData;
  shouldRedirect: boolean;
}

export const submitCreateContractForm = createAction<ISubmitCreateContractForm>(
  `${reducerName}.SUBMIT_CREATE_CONTRACT_FORM`
);

export interface ISubmitEditContractForm {
  contractData: IContractFormData;
  shouldRedirect: boolean;
  refetchSinglePage?: boolean;
}

export const submitEditContractForm = createAction<ISubmitEditContractForm>(`${reducerName}.SUBMIT_EDIT_CONTRACT_FORM`);
