import { DocumentLinesResponse } from '@/generated';
import { getList, resetState, setList, setLoading } from '@core-ui/reducers_handlers';
import { createAction } from 'redux-actions';

export const reducerName = 'modals.master_document.lines';

export const setMasterDocumentModalLinesListLoading = setLoading(reducerName);
export const resetMasterDocumentModalLines = resetState(reducerName);

export interface IGetMasterDocumentLine {
  documentId?: number;
}

export const getMasterDocumentModalDocumentLines = getList<IGetMasterDocumentLine>(reducerName);
export const setMasterDocumentModalDocumentLines = setList<DocumentLinesResponse>(reducerName);

export interface IUpdateLineErpCategory {
  documentId: number;
  lineId: number;
  categoryId: string;
}

export const updateLineErpCategory = createAction<IUpdateLineErpCategory>(`${reducerName}.UPDATE_LINE_ERP_CATEGORY`);
