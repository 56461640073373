import { IUseQuoteModalData } from '@/pages/backoffice/Modals/types';

export default class UseQuoteAsInvoiceModalData implements IUseQuoteModalData {
  requestId: string;
  documentId: number;

  constructor(requestId: string, documentId: number) {
    this.requestId = requestId;
    this.documentId = documentId;
  }
}
