import Button from '@core-ui/button';
import Tabs, { ITab } from '@core-ui/tabs';
import { buildURL } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import { DownloadSimple, Info, Invoice } from '@phosphor-icons/react';
import { BACKEND_URL } from 'app/consts/env';
import { FormattedMessage } from 'components/Intl';
import { useAppSelector } from 'hooks/useAppSelector';
import { MASTER_DOCUMENT_TAB_ENUM } from 'pages/backoffice/Modals/MasterDocumentModal/consts';
import { setMasterDocumentTab } from 'pages/backoffice/Modals/MasterDocumentModal/MasterDocumentModalRoot/actions';
import React from 'react';
import { useDispatch } from 'react-redux';
import selector from './selector';
import useStyles from './styles';

const MasterDocumentModalTabs = () => {
  const { tab, documentId } = useAppSelector(selector);

  const styles = useStyles();
  const dispatch = useDispatch();

  const handleOpenDocumentTab = () => {
    dispatch(setMasterDocumentTab(MASTER_DOCUMENT_TAB_ENUM.DOCUMENT));
  };

  const handleOpenLinesTab = () => {
    dispatch(setMasterDocumentTab(MASTER_DOCUMENT_TAB_ENUM.LINES));
  };

  const handleDownloadCsv = () => {
    if (documentId) {
      window.open(buildURL(`${BACKEND_URL}/document/${documentId}/csv`));
    }
  };

  const tabs: ITab[] = [
    {
      value: MASTER_DOCUMENT_TAB_ENUM.DOCUMENT,
      label: <FormattedMessage id="label.document" />,
      onClick: handleOpenDocumentTab,
      icon: (
        <SvgIcon>
          <Info />
        </SvgIcon>
      ),
    },
    {
      value: MASTER_DOCUMENT_TAB_ENUM.LINES,
      label: <FormattedMessage id="label.lines" />,
      onClick: handleOpenLinesTab,
      icon: (
        <SvgIcon>
          <Invoice />
        </SvgIcon>
      ),
    },
  ];

  return (
    <Grid item container sx={styles.container}>
      <Tabs size="small" tabs={tabs} active={tab} />

      <Button
        variant="text"
        color="blue"
        compact
        label={<FormattedMessage id="label.download_csv" />}
        startIcon={<DownloadSimple />}
        onClick={handleDownloadCsv}
      />
    </Grid>
  );
};

export default MasterDocumentModalTabs;
