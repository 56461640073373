import { useAppSelector } from '@/hooks/useAppSelector';
import { IQuote } from '@/pages/backoffice/Requests/types';
import QuoteItem from '@/pages/backoffice/Requests/SingleRequest/QuotesList/components/QuoteItem';
import { FC, useMemo } from 'react';

import selector from './selector';

interface IProps {
  quote: IQuote;
}

const QuoteItemWrapper: FC<IProps> = (props) => {
  const { quote } = props;

  const memoizedSelector = useMemo(selector, []);
  const { checked } = useAppSelector((state) => memoizedSelector(state, { quoteId: quote.id }));

  return <QuoteItem isCompare document={quote} checked={checked} />;
};

export default QuoteItemWrapper;
