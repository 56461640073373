import State from 'app/types/state';
import { NoDataContainerSelector } from 'components/NoDataContainer/NoDataContainer';
import { MasterDocumentModalData } from 'pages/backoffice/Modals/command';
import { createSelector } from 'reselect';

export const getMasterDocumentRootState = (state: State) => state.backoffice.masterDocumentModal.root;
export const getMasterDocumentRootValue = (state: State) => state.backoffice.masterDocumentModal.root.value;
export const getMasterDocumentRootLoading = (state: State) => state.backoffice.masterDocumentModal.root.loading;

export const getMasterDocumentTab = (state: State) => state.backoffice.masterDocumentModal.root.tab;

export const getNoDataContainerData: NoDataContainerSelector = createSelector(
  [getMasterDocumentRootState],
  (state) => ({
    loading: state.loading,
    loaded: state.loaded,
    hasData: state.hasData,
    error: state.error,
  })
);

const getModalsData = (state: State) => state.backoffice.modals.data as MasterDocumentModalData;

const getImageLinks = createSelector(
  [getMasterDocumentRootValue],
  (document) => document?.files?.map((file) => file.link) ?? []
);

export default createSelector([getModalsData, getImageLinks], (modalsData, imageLinks) => ({
  documentId: modalsData?.documentId,
  imageLinks,
}));
