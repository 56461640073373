import { SxProps, Theme } from '@mui/material/styles';
import { MASTER_DOCUMENT_CONTENT_TABS_HEIGHT } from 'pages/backoffice/Modals/MasterDocumentModal/consts';

export default (): Record<string, SxProps<Theme>> => ({
  container: {
    justifyContent: 'space-between',
    alignItems: 'center',
    wrap: 'nowrap',
    paddingX: 5,
    height: `${MASTER_DOCUMENT_CONTENT_TABS_HEIGHT}px`,
    boxShadow: '0px 4px 12px 0px #07070814', // copied from figma
    borderRadius: 0,
  },
});
