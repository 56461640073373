import { IDeleteWallet } from '@/pages/backoffice/Wallets/WalletsList/actions';

export default class DeleteWalletModalData implements IDeleteWallet {
  id: number;
  shouldRedirect: boolean;

  constructor(id: number, shouldRedirect: boolean) {
    this.id = id;
    this.shouldRedirect = shouldRedirect;
  }
}
