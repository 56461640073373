import State from '@/app/types/state';
import { SendToErpRequestInvoicesBatchModalData } from '@/pages/backoffice/Modals/command';
import { createSelector } from 'reselect';
import { vehicleIdSelector } from '@/app/selectors';

const getData = (state: State) => state.backoffice.modals.data as SendToErpRequestInvoicesBatchModalData;

const getLoading = (state: State) => state.backoffice.modals.loading as boolean;

export default createSelector([vehicleIdSelector, getData, getLoading], (boatId, data, loading) => ({
  boatId,
  requestId: data?.requestId,
  loading,
}));
