import reducer, { Data } from '@core-ui/reducers_handlers';
import { DocumentSearchResult } from '@/generated';
import { handleActions } from 'redux-actions';
import Immutable from '@core-ui/immutable';
import * as actions from './actions';

export interface ILedgerDocumentState extends Data<DocumentSearchResult> {}

const defaultState = Immutable<ILedgerDocumentState>({
  loaded: false,
  hasData: false,
  loading: false,
});

export default handleActions<typeof defaultState, unknown>(
  {},
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
