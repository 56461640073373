import { DocumentSearchResult } from '@/generated';
import { resetState, setLoading } from '@core-ui/reducers_handlers';
import { getData, setData } from '@core-ui/reducers_handlers/actions';
import { MASTER_DOCUMENT_TAB_ENUM } from 'pages/backoffice/Modals/MasterDocumentModal/consts';
import { createAction } from 'redux-actions';

export const reducerName = 'modals.master_document.root';

export const resetMasterDocument = resetState(reducerName);
export const setMasterDocumentLoading = setLoading(reducerName);

export interface IGetMasterDocument {
  documentId?: number;
}

export const getMasterDocument = getData<IGetMasterDocument>(reducerName);
export const setMasterDocument = setData<DocumentSearchResult>(reducerName);

export const setMasterDocumentTab = createAction<MASTER_DOCUMENT_TAB_ENUM>(`${reducerName}.SET_MASTER_DOCUMENT_TAB`);
