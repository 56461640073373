import { getLevelUserApprovalEnabled } from '@/app/selectors';
import { toMutableArray } from '@/app/utils/app';
import {
  getClientCostArticlesDictOptions,
  getCostArticlesDictOptions,
  getCostCentersDictOptions,
  getRequestsDictOptions,
  getUploadedByOptions,
  getVendorsDictOptions,
} from '@/dictionary/selector';
import { getErpCategoriesFilter, getFilters } from '@/pages/backoffice/Ledger/LedgerFilters/selector';
import { getWalletsOptions } from '@/pages/backoffice/Wallets/WalletsList/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [
    getFilters,
    getErpCategoriesFilter,
    getUploadedByOptions,
    getCostCentersDictOptions,
    getWalletsOptions,
    getVendorsDictOptions,
    getRequestsDictOptions,
    getCostArticlesDictOptions,
    getClientCostArticlesDictOptions,
    getLevelUserApprovalEnabled,
  ],
  (
    filters,
    erpCategoriesFilter,
    uploadedByOptions,
    costCentersOptions,
    walletsOptions,
    vendorsOptions,
    requestsOptions,
    costArticlesOptions,
    clientCostArticlesOptions,
    levelUserApprovalEnabled
  ) => ({
    filters: {
      ...filters,

      documentType: toMutableArray(filters.documentType),
      vendor: toMutableArray(filters.vendor),
      location: toMutableArray(filters.location),
      uploadedBy: toMutableArray(filters.uploadedBy),
      uploadedFrom: toMutableArray(filters.uploadedFrom),
      costCenters: toMutableArray(filters.costCenters),
      costArticles: toMutableArray(filters.costArticles),
      erpCategories: toMutableArray(erpCategoriesFilter),
      walletIds: toMutableArray(filters.walletIds),
      contractIds: toMutableArray(filters.contractIds),
      requestIds: toMutableArray(filters.requestIds),
    },

    uploadedByOptions,
    clientCostArticlesOptions,
    costCentersOptions,
    walletsOptions,
    vendorsOptions,
    requestsOptions,
    costArticlesOptions,
    levelUserApprovalEnabled,
  })
);
