import State from '@/app/types/state';
import { getCurrencyDictOptions } from '@/dictionary/selector';
import { CreateEditWalletModalData } from '@/pages/backoffice/Modals/command';
import { createSelector } from 'reselect';

const getLoading = (state: State) => state.backoffice.modals.loading;

export const getInitialWalletData = (state: State) =>
  (state.backoffice.modals.data as CreateEditWalletModalData)?.walletData;

const getRedirect = (state: State) => (state.backoffice.modals.data as CreateEditWalletModalData)?.shouldRedirect;

export default createSelector(
  [getInitialWalletData, getRedirect, getCurrencyDictOptions, getLoading],
  (initialValues, redirect, currencyOptions, loading) => ({
    isModeEdit: Boolean(initialValues),
    initialValues,
    redirect,
    currencyOptions,
    loading,
  })
);
