import { IDeclineDocumentModalData } from '@/pages/backoffice/Modals/DeclineDocumentModal/actions';

export default class DeclineDocumentModalData implements IDeclineDocumentModalData {
  id: number;
  reloadDocumentList: boolean | undefined;

  constructor(id: number, reloadDocumentList?: boolean) {
    this.id = id;
    this.reloadDocumentList = reloadDocumentList;
  }
}
