import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { SINGLE_DOCUMENT_LINE_COLUMN_WIDTH } from '@/pages/backoffice/Ledger/consts';
import { ITableColumn } from '@/pages/backoffice/types';
import { NoteBlank } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'components/Intl';

export const HEADERS: ITableColumn[] = [
  {
    key: 'line_name',
    title: <FormattedMessage id="table.header.line_name" />,
    width: SINGLE_DOCUMENT_LINE_COLUMN_WIDTH,
  },
  {
    key: 'cost_article',
    title: <FormattedMessage id="table.header.cost_article" />,
    width: 184,
  },
  {
    key: 'file_icon',
    title: (
      <Tooltip title={<FormattedMessage id="label.description" />} placement="top" arrow>
        <SvgIcon>
          <NoteBlank />
        </SvgIcon>
      </Tooltip>
    ),
    align: 'center',
    width: 46,
  },
  {
    key: 'erp_category',
    title: <FormattedMessage id="table.header.erp_category" />,
    width: 230,
  },
  {
    key: 'quantity',
    title: <FormattedMessage id="table.header.quantity" />,
    align: 'center',
    width: 80,
  },
  {
    key: 'price_per_unit',
    title: <FormattedMessage id="table.header.price_per_unit" />,
    align: 'right',
    width: 106,
  },
  {
    key: 'vat_percentage',
    title: <FormattedMessage id="table.header.vat_percentage" />,
    width: 74,
    align: 'right',
  },
  {
    key: 'vat',
    title: <FormattedMessage id="table.header.vat" />,
    width: 74,
    align: 'right',
  },
  {
    key: 'currency',
    title: (
      <Tooltip title={<FormattedMessage id="table.header.currency" />} placement="top" arrow>
        <div>
          <FormattedMessage id="table.header.currency_short" />
        </div>
      </Tooltip>
    ),
    width: 54,
    align: 'center',
    right: 160,
    shadow: 'dynamicLeft',
  },
  {
    key: 'line_amount',
    title: <FormattedMessage id="table.header.line_amount" />,
    width: 160,
    align: 'right',
    right: 0,
  },
];
