import { changePropState } from '@core-ui/reducers_handlers';
import { Nullable } from '@core-ui/types';
import { POST, PUT } from '@/api/oceanApi';
import { ROUTES } from '@/app/consts/routes';
import { responseError } from '@/app/sagas';
import { vehicleIdSelector } from '@/app/selectors';
import State from '@/app/types/state';
import history from '@/history';
import { BACKOFFICE_TABS } from '@/pages/backoffice/consts';
import { getRequest, setRequest } from '@/pages/backoffice/Requests/SingleRequest/QuotesList/actions';
import { DETAIL_REQUEST_TABS } from '@/pages/backoffice/Requests/SingleRequest/types';
import {
  CASH_PAYMENT_TYPE_ENUM,
  FILE_UPLOAD_TYPE,
  INVOICE_FILE_UPLOAD_TYPE_ENUM,
  PAYMENT_REQUEST_TYPE_ENUM,
} from '@/pages/backoffice/Requests/types';
import { all, call, put, select, take, takeLatest } from '@redux-saga/core/effects';
import { Action } from 'redux-actions';
import { closeBackofficeModal, reducerName } from '../actions';
import { IREQ_FILE_INFO } from '../reducer';
import * as actions from './actions';
import { IRequestForm } from './types';

export const actionChangeProps = changePropState(reducerName);

const createServerTime = (date: Nullable<number | string | Date>) => {
  if (!date) {
    return null;
  }

  if (typeof date === 'object') {
    return date.getTime() / 1000;
  }

  return Number(date) / 1000;
};

function* submitRequestForm({ payload }: Action<IRequestForm>) {
  try {
    const boat_id: number = yield select(vehicleIdSelector);

    yield put(actionChangeProps(['loading'], true));

    const method = payload.id ? PUT : POST;
    const url = payload.id ? `/requests/${payload.id}` : '/requests';

    // TODO: добавить типизацию когда будем переводить Requests на сгенеренные интерфейсы
    // @ts-ignore
    const response = yield call(method, url, {
      ...payload,
      expired_at: createServerTime(payload.expired_at),
      cash_payment_type: payload.request_type === PAYMENT_REQUEST_TYPE_ENUM.CASH ? CASH_PAYMENT_TYPE_ENUM.CASH : null,
      boat_id,
    });

    yield put(actionChangeProps(['data'], response));
    yield put(actions.submitRequestFormFiles(payload));
    yield put(getRequest({ requestId: response.id }));

    yield take(setRequest);
    yield put(closeBackofficeModal());

    if (!payload.id) {
      history.push(
        `/${boat_id}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.REQUESTS}/${response.id}/${DETAIL_REQUEST_TABS.ABOUT}`
      );
    }
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(actionChangeProps(['loading'], false));
  }
}

const createFormData = (payload: IREQ_FILE_INFO, requestId: string) => {
  const formData = new FormData();
  formData.append('request_id', requestId);
  formData.append('doctype', payload.docType);
  payload.files.forEach((file) => formData.append('fileobjects', file));

  return formData;
};

function* submitRequestFormFiles({ payload }: Action<IRequestForm>) {
  const requestId: string = yield select((state: State) => (state.backoffice.modals.data as IRequestForm)?.id);

  if (!requestId) {
    return;
  }

  const submitQueue = [];
  if (payload.defaultFiles?.length) {
    submitQueue.push(
      createFormData(
        {
          files: payload.defaultFiles,
          docType: FILE_UPLOAD_TYPE.DEFAULT,
        },
        requestId
      )
    );
  }

  if (payload.invoiceFiles?.length) {
    submitQueue.push(
      createFormData(
        {
          files: payload.invoiceFiles,
          docType: payload.invoiceType || INVOICE_FILE_UPLOAD_TYPE_ENUM.PREPAYMENT,
        },
        requestId
      )
    );
  }

  if (payload.quoteFiles?.length) {
    submitQueue.push(
      createFormData(
        {
          files: payload.quoteFiles,
          docType: FILE_UPLOAD_TYPE.QUOTE,
        },
        requestId
      )
    );
  }

  if (payload.receiptFiles?.length) {
    submitQueue.push(
      createFormData(
        {
          files: payload.receiptFiles,
          docType: FILE_UPLOAD_TYPE.RECEIPT,
        },
        requestId
      )
    );
  }

  try {
    if (submitQueue.length) {
      yield all(
        submitQueue.map((formData) =>
          call(POST, '/files', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
        )
      );
    }
    yield put(getRequest({ requestId }));
  } catch (e) {
    yield call(responseError, e);
  }
}

export default [
  takeLatest(actions.submitRequestForm, submitRequestForm),
  takeLatest(actions.submitRequestFormFiles, submitRequestFormFiles),
];
